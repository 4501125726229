import { request, demoRequest } from "../../utils";

export const activateTeammate = async (values) => {
  try {
    const res = await request.post("/business/users/accept-invitation", values);
    return res?.data?.data;
  } catch (error) {
    if (error?.response?.data?.data) {
      throw new Error(Object.values(error.response.data.data).join(", "));
    }
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getTeammates = async ({
  page,
  business_uid = undefined,
  per_page,
}) => {
  try {
    const url = `/business/users/${business_uid}`;
    const res = await request.get(url, {
      params: { page, per_page },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getRoles = async () => {
  try {
    const res = await request.get("/business-team-role");
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createTeammateUser = async (values) => {
  try {
    const res = await request.post("/business/users", values);
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const toggleUserStatus = async ({ user_uid, business_uid }) => {
  try {
    const res = await request.post(
      `/business/users/toggle-status/${user_uid}`,
      {
        business_uid,
      }
    );
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const switchRole = async ({ user_uid, role }) => {
  try {
    const res = await request.post(`/user/switch-teammate-role/${user_uid}`, {
      business_team_role_uid: role,
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const deleteTeammate = async (user_uid) => {
  try {
    const res = await request.delete(`/business/users/delete/${user_uid}`);
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
