import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useToast } from "@chakra-ui/react";
import { authDataState } from "../atoms/auth";
import { clearStorage, setAuthToken, setMode, setAuthUser } from "../helpers";
import { getUserProfile, loginUser, updateUser, googleAuth } from "../services";
import mixpanel from "mixpanel-browser";

export const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [authData, setAuthData] = useRecoilState(authDataState);

  const login = async (mode = "LIVE", { email, password }) => {
    setLoading(true);
    clearStorage();
    try {
      const response = await loginUser(mode, { email, password });
      setAuthToken(response?.access_token);
      setAuthUser(response?.user);
      setAuthData({
        ...authData,
        userData: response?.user,
        loggedIn: true,
        authToken: response?.access_token,
      });

      mixpanel.register( {
        first_name: response?.user?.first_name,
        last_name: response?.user?.last_name,
        user_id: response?.user?.uid,
        Email:response?.user?.email,
        Name: `${response?.user?.last_name} ${response?.user?.first_name}`,
        Phone: response?.user?.phone_number,
      });
      mixpanel.identify(response?.user?.email);
      mixpanel.people.set( {
        Phone: response?.user?.phone_number,
        Email:response?.user?.email,
        first_name: response?.user?.first_name,
        last_name: response?.user?.last_name,
        user_id: response?.user?.uid,
      });
      mixpanel.track('login');
      if (mode === "TEST") {
        setMode("TEST");
        toast({
          title: "Success",
          description: "You are now in test mode",
          status: "sucsess",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        window.location.reload();
      } else {
        setMode("LIVE");
      }
      navigate("/");
    } catch (error) {
      if (error.message === "User not active, please contact admin") {
        toast({
          title: "Hello",
          description: "Hi! Let's help you verify your email first 🙂",
          status: "info",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/verify-email", {
          state: {
            email,
            resend: true,
          },
        });
      } else {
        toast({
          title: "Error",
          description:
            mode === "LIVE"
              ? error.message
              : "You can't switch to test mode at this moment",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    setLoading(false);
  };

  const googleLogin = async ({
    email,
    first_name,
    last_name,
    provider_user_id,
    provider_uid,
  }) => {
    setLoading(true);
    try {
      const response = await googleAuth({
        email,
        first_name,
        last_name,
        provider_user_id,
        provider_uid,
      });
      setAuthToken(response?.access_token);
      setAuthData({
        ...authData,
        userData: response?.user,
        loggedIn: true,
        authToken: response?.access_token,
      });

      mixpanel.track("login", {
        first_name: response?.user?.first_name,
        last_name: response?.user?.last_name,
        user_id: response?.user?.uid,
        channel: "google",
      });
    } catch (error) {
      if (error.message === "User not active, please contact admin") {
        toast({
          title: "Hello",
          description: "Hi! Let's help you verify your email first 🙂",
          status: "info",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/verify-email",{
          state: {
            email,
            resend: true,
          },
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    setLoading(false);
  };

  const getProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUserProfile();
      setAuthData({
        ...authData,
        userData: response?.user,
        loggedIn: true,
      });
      setAuthUser(response?.user);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  }, []);

  const updateProfile = async (values) => {
    setLoading(true);
    try {
      const response = await updateUser(authData?.userData.uid, values);
      // setAuthData({
      //   ...authData,
      //   userData: response?.user,
      // });
      await getProfile();
      toast({
        title: "Success",
        description: "Your profile has been updated!",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  };

  const logout = () => {
    setAuthData({
      userData: null,
      loggedIn: false,
      authToken: null,
    });
    clearStorage();
    window.location.href = "/login";
  };

  return {
    state: {
      loading,
      ...authData,
    },
    getProfile,
    updateProfile,
    login,
    googleLogin,
    logout,
  };
};
