import dayjs from "dayjs";

export const returnDecimalPlace = (data, dp) => {
  if (!data) return;
  return parseFloat(data).toFixed(dp);
};

export const formatMoney = (currency, amount, decimalPoints = 2) => {
  return `${currency} ${(parseFloat(amount) || 0).toFixed(decimalPoints)} `;
  //   .toLocaleString('en-US', {
  //   minimumFractionDigits: decimalPoints,
  // })}`;
  // return new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: currency,
  //   minimumFractionDigits: decimalPoints,
  // });
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const numberFormat = (value=0, currency = '') => {
  if (value || value === 0) {
    var nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
    return `${currency} ${nf.format(value).replace(/\D00(?=\D*$)/, '')}`;
  }
};

export const convertDate = (date) => (dayjs(date).format('YYYY-MM-DD'))
