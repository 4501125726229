import { atom } from 'recoil';
import { getAuthUser } from '../helpers';

const authDataState = atom({
  key: 'authData',
  default: {
    loggedIn: false,
    authToken: undefined,
    userData: getAuthUser(),
  },
});

export { authDataState };
