import { useState, useCallback } from "react";
import { convertDate } from "../helpers";

export const usePagination = () => {
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(1);
  const [pagination, setPagination] = useState({
    page:1,
    perPage:10
  });
  
  const [filter, setFilter] = useState({
    start_date: convertDate(new Date()),
    end_date: convertDate(new Date()),
    isfilter:true
  });

  const handlePageChange = (p) => {
    if (p) {
      setPagination({ ...pagination, page: p });
    }
  };

  const handlePerPage = (p) => {
    
    if (p) {
      setPagination({ ...pagination, perPage: p });
    }
  };

  const handleFilterOption = useCallback((data) => {
    setFilter(data);
  }, []);

  return {
    pagination,
    filter,
    handlePageChange,
    handleFilterOption,
    handlePerPage,
  };
};
