import React from "react";

// chakra
import { Text } from "@chakra-ui/react";

import CustomModal from "../UI/CustomModal";
import BusinessForm from "./BusinessForm";

const AddNewBusiness = ({ clear, disclosure }) => {
  return (
    <CustomModal
      size="md"
      isCentered
      disclosure={disclosure}
      clear={clear}
      closeAble={true}
      Title={() => (
        <Text fontSize="20px" fontWeight="500">
          Add new Business
        </Text>
      )}
    >
      <BusinessForm />
    </CustomModal>
  );
};

export default AddNewBusiness;
