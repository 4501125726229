import { lazy } from "react";

const Dashboard = lazy(() => import("../../pages/home"));
//SMS
const SMSOverview = lazy(() => import("../../pages/sms/Overview"));
const SMSSenderID = lazy(() => import("../../pages/sms/SenderID"));
const SMSReports = lazy(() => import("../../pages/sms/Reports"));
const TestSMS = lazy(() => import("../../pages/sms/TestSMS"));

//Voice
const VoiceOverview = lazy(() => import("../../pages/voice/Overview"));
const TestVoice = lazy(() => import("../../pages/voice/TestVoice"));
const VoiceReports = lazy(() => import("../../pages/voice/Reports"));

//Email
const EmailOverview = lazy(() => import("../../pages/email/Overview"));
const EmailReports = lazy(() => import("../../pages/email/Reports"));
const EmailDomain = lazy(() => import("../../pages/email/Domain"));
const EmailDomainSetup = lazy(() =>
  import("../../pages/email/Domain/AddDomain")
);
const EmailSubscription = lazy(() => import("../../pages/email/Subscription"));
const EmailSMTP = lazy(() => import("../../pages/email/Domain/SMTP"));
const EmailSender = lazy(() => import("../../pages/email/Sender"));
const EmailManageDomain = lazy(() =>
  import("../../pages/email/Domain/ManageDomain")
);

//Whatsapp
const WhatsappOverview = lazy(() => import("../../pages/whatsapp/Overview"));
const TestWhatsapp = lazy(() => import("../../pages/whatsapp/TestWhatsapp"));
const WhatsappReports = lazy(() => import("../../pages/whatsapp/Reports"));
const WhatsappActivateNumber = lazy(() =>
  import("../../pages/whatsapp/ActivateNumber")
);
const WhatsappBusiness = lazy(() =>
  import("../../pages/whatsapp/ActivateNumber/WhatsappBusiness")
);
const UpdateWhatsappNumber = lazy(() =>
  import("../../pages/whatsapp/ActivateNumber/UpdateWhatsappNumber")
);

const WhatsappTemplate = lazy(() => import("../../pages/whatsapp/Template"));

const WhatsappCreateTemplate = lazy(() =>
  import("../../pages/whatsapp/Template/CreateTemplate")
);

//Otp
const OtpOverview = lazy(() => import("../../pages/otp/Overview"));
const TestOtp = lazy(() => import("../../pages/otp/TestOtp"));
const OtpReports = lazy(() => import("../../pages/otp/Reports"));

// SIP Trunking
const SIPReports = lazy(() => import("../../pages/sip-trunking/reports"))
const SIPEndpoint = lazy(() => import("../../pages/sip-trunking/endpoint"))
const CreateSIPEndpoint = lazy(() => import("../../pages/sip-trunking/endpoint/createEndpoint"))

//Number
const NumberInsightOverview = lazy(() =>
  import("../../pages/number/Overview")
);
const NumberInsightRequest = lazy(() =>
  import("../../pages/number/NumberInsight")
);
const NumberInsightReports = lazy(() =>
  import("../../pages/number/Reports")
);
const BuyNumber = lazy(() => import("../../pages/number/BuyNumber"));
const EditNumberPurchase = lazy(() =>
  import("../../pages/number/BuyNumber/EditNumber")
);
const ManageSubscription = lazy(() =>
  import("../../pages/number/BuyNumber/ManageSubscription")
);

const NumberBin = lazy(() =>
  import("../../pages/number/Bin")
);
const CreateNumberBin = lazy(() =>
  import("../../pages/number/Bin/CreateNumberBin")
);

const UpdateNumberBin = lazy(() =>
  import("../../pages/number/Bin/CreateNumberBin")
);

const NumberSearch = lazy(() =>
  import("../../pages/number/BuyNumber/SearchNumber")
);
const ViewNumberReport = lazy(() =>  import("../../pages/number/Reports/Details") )
const PhoneCall = lazy(() => import("../../pages/number/PhoneCall"));

//Wallet
const WalletTransaction = lazy(() => import("../../pages/wallet/transactions"));
const Pricing = lazy(() => import("../../pages/wallet/pricing"));
const Wallet = lazy(() => import("../../pages/wallet/"));
const WalletReceipt = lazy(() =>
  import("../../pages/wallet/components/Receipt")
);

//Account
const ApiKeysWebhooks = lazy(() =>
  import("../../pages/settings/ApiKeysWebhooks")
);
const ProfileSettings = lazy(() =>
  import("../../pages/settings/ProfileSettings")
);
const BusinessProfile = lazy(() =>
  import("../../pages/settings/BusinessProfile")
);
const GeneralPreference = lazy(() =>
  import("../../pages/settings/GeneralPreference")
);

const Onboarding = lazy(() => import("../../pages/auth/Onboarding"));
const Teammates = lazy(() => import("../../pages/teams/ViewTeammates"));

export const protectedRoute = [
  {
    name: "Home",
    path: "/",
    exact: true,
    component: Dashboard,
    protected: true,
  },
  {
    name: "SMS Overview",
    path: "/channel/sms/overview",
    exact: true,
    component: SMSOverview,
    protected: true,
  },
  {
    name: "SMS Sender id",
    path: "/channel/sms/sender",
    exact: true,
    component: SMSSenderID,
    protected: true,
  },
  {
    name: "SMS test",
    path: "/channel/sms/test",
    exact: true,
    component: TestSMS,
    protected: true,
  },
  {
    name: "SMS Reports",
    path: "/channel/sms/reports",
    exact: true,
    component: SMSReports,
    protected: true,
  },
  //Voice
  {
    name: "Voice Overview",
    path: "/channel/voice/overview",
    exact: true,
    component: VoiceOverview,
    protected: true,
  },
  {
    name: "Voice Test",
    path: "/channel/voice/test",
    exact: true,
    component: TestVoice,
    protected: true,
  },
  {
    name: "Voice reports",
    path: "/channel/voice/reports",
    exact: true,
    component: VoiceReports,
    protected: true,
  },

  //Email

  {
    name: "Email Overview",
    path: "/channel/email/overview",
    exact: true,
    component: EmailOverview,
    protected: true,
  },
  {
    name: "Email Domain",
    path: "/channel/email/domain",
    exact: true,
    component: EmailDomain,
    protected: true,
  },
  {
    name: "Email Domain SMTP",
    path: "/channel/email/domain/smtp/:domain_uid",
    exact: true,
    component: EmailSMTP,
    protected: true, 
  },
  // {
  //   name: "Email Sender",
  //   path: "/channel/email/sender",
  //   exact: true,
  //   component: EmailSender,
  //   protected: true,
  // },
  {
    name: "Email Domain Manage",
    path: "/channel/email/domain/:domain_uid",
    exact: true,
    component: EmailManageDomain,
    protected: true,
  },
  {
    name: "Email Domain Setup",
    path: "/channel/email/domain/setup",
    exact: true,
    component: EmailDomainSetup,
    protected: true,
  },
  {
    name: "Email Domain Smtp",
    path: "/channel/email/domain/smtp",
    exact: true,
    component: EmailDomain,
    protected: true,
  },
  {
    name: "Email Subscription",
    path: "/channel/email/subscription",
    exact: true,
    component: EmailSubscription,
    protected: true,
  },
  {
    name: "Email reports",
    path: "/channel/email/reports",
    exact: true,
    component: EmailReports,
    protected: true,
  },
  {
    name: "Email reports",
    path: "/channel/email/reports",
    exact: true,
    component: EmailReports,
    protected: true,
  },
  //Number
  {
    name: "Number Overview",
    path: "/channel/number/overview",
    exact: true,
    component: NumberInsightOverview,
    protected: true,
  },
  {
    name: "Number Request",
    path: "/channel/number/request",
    exact: true,
    component: NumberInsightRequest,
    protected: true,
  },
  {
    name: "Number Reports",
    path: "/channel/number/reports",
    exact: true,
    component: NumberInsightReports,
    protected: true,
  },
  {
    name: "Call Details",
    path: "/channel/number/reports/details",
    exact: true,
    component: ViewNumberReport,
    protected: true,
  },
  {
    name: "Number test call",
    path: "/channel/number/call",
    exact: true,
    component: PhoneCall,
    protected: true,
  },
  {
    name: "Number Reports",
    path: "/channel/number/purchase",
    exact: true,
    component: BuyNumber,
    protected: true,
  },
  {
    name: "Number edit",
    path: "/channel/number/purchase/edit/:uid",
    exact: true,
    component: EditNumberPurchase,
    protected: true,
  },
  {
    name: "Number search",
    path: "/channel/number/request/search",
    exact: true,
    component: NumberSearch,
    protected: true,
  },
  {
    name: "Number subscription",
    path: "/channel/number/subscription",
    exact: true,
    component: ManageSubscription,
    protected: true,
  },
  {
    name: "XML bin",
    path: "/channel/number/bin",
    exact: true,
    component: NumberBin,
    protected: true,
  },
   {
    name: "Create XML bin",
    path: "/channel/number/bin/:type",
    exact: true,
    component: CreateNumberBin,
    protected: true,
  },
  //Whatsapp
  {
    name: "Whatsapp Overview",
    path: "/channel/whatsapp/overview",
    exact: true,
    component: WhatsappOverview,
    protected: true,
  },
  {
    name: "Whatsapp Test",
    path: "/channel/whatsapp/test",
    exact: true,
    component: TestWhatsapp,
    protected: true,
  },
  {
    name: "Whatsapp reports",
    path: "/channel/whatsapp/reports",
    exact: true,
    component: WhatsappReports,
    protected: true,
  },
  {
    name: "Whatsapp activate number",
    path: "/channel/whatsapp/activate-number",
    exact: true,
    component: WhatsappActivateNumber,
    protected: true,
  },
  {
    name: "Whatsapp business",
    path: "/channel/whatsapp/business-api",
    exact: true,
    component: WhatsappBusiness,
    protected: true,
  },
  {
    name: "Whatsapp Number",
    path: "/channel/whatsapp/update/:number",
    exact: true,
    component: UpdateWhatsappNumber,
    protected: true,
  },
  {
    name: "Whatsapp Template",
    path: "/channel/whatsapp/template",
    exact: true,
    component: WhatsappTemplate,
    protected: true,
  },
  {
    name: "Whatsapp Create Template",
    path: "/channel/whatsapp/template/create",
    exact: true,
    component: WhatsappCreateTemplate,
    protected: true,
  },

  //Otp

  {
    name: "Otp Overview",
    path: "/channel/otp/overview",
    exact: true,
    component: OtpOverview,
    protected: true,
  },
  {
    name: "Otp Test",
    path: "/channel/otp/test",
    exact: true,
    component: TestOtp,
    protected: true,
  },
  {
    name: "Otp reports",
    path: "/channel/otp/reports",
    exact: true,
    component: OtpReports,
    protected: true,
  },

  //SIP 
  {
    name: "SIP endpoint",
    path: "/channel/sip/endpoint",
    exact: true,
    component: SIPEndpoint,
    protected: true,
  },
  {
    name: "SIP reports",
    path: "/channel/sip/reports",
    exact: true,
    component: SIPReports,
    protected: true,
  },
  {
    name: "SIP reports",
    path: "/channel/sip/endpoint/create",
    exact: true,
    component: CreateSIPEndpoint,
    protected: true,
  },

  //Wallet
  {
    name: "Wallet",
    path: "/wallet",
    exact: true,
    component: Wallet,
    protected: true,
  },
  {
    name: "Wallet",
    path: "/wallet/receipt",
    exact: true,
    component: WalletReceipt,
    protected: true,
  },
  {
    name: "Wallet-transaction",
    path: "/transaction",
    exact: true,
    component: WalletTransaction,
    protected: true,
  },
  {
    name: "Pricing",
    path: "/pricing",
    exact: true,
    component: Pricing,
    protected: true,
  },

  //Accounts
  {
    name: "ApiKeysWebhooks",
    path: "/accounts/api-webhooks",
    exact: true,
    component: ApiKeysWebhooks,
    protected: true,
  },
  {
    name: "Profile Settings",
    path: "/accounts/settings",
    exact: true,
    component: ProfileSettings,
    protected: true,
  },
  {
    name: "General Preference",
    path: "/accounts/general-preference",
    exact: true,
    component: GeneralPreference,
    protected: true,
  },
  {
    name: "Teammate",
    path: "/teams",
    exact: true,
    component: Teammates,
    protected: true,
  },
  {
    name: "Business Profile",
    path: "/accounts/business-profile",
    exact: true,
    component: BusinessProfile,
    protected: true,
  },
  {
    name: "Add Business Profile",
    path: "/accounts/business/add",
    key: "dadaas",
    exact: true,
    component: BusinessProfile,
    protected: true,
  },
];

export const completeRouteList = [
  {
    name: "Onboarding",
    path: "/onboarding",
    component: Onboarding,
    protected: false,
    exact: true,
  },
];
