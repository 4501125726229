import axios from 'axios';
import { ENV } from '../helpers';

export const fileUpload = async ({ file, file_name, upload_preset }) => {
  try {
    const url = `https://api.cloudinary.com/v1_1/${ENV.CLOUDINARY_NAME}/auto/upload`;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('public_id', file_name);
    formData.append('upload_preset', upload_preset);

    const res = await axios.post(url, formData);
    return res?.data;
  } catch (error) {
    console.log({ ...error });
    const err = error?.response?.data?.error.message || error?.message;
    throw new Error(err);
  }
};

export const fileUploadNew = async ({ file, file_name, upload_preset }) => {
  try {
    const url = `https://api.cloudinary.com/v1_1/${ENV.CLOUDINARY_NAME}/auto/upload`;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('public_id', file_name);
    formData.append('upload_preset', upload_preset);

    const res = await axios.post(url, formData);
    return res?.data;
  } catch (error) {
    throw error;
  }
};
