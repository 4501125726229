import { atom } from "recoil";

const businessDataState = atom({
  key: "business",
  default: {
    businesses: [],
    activeBusiness: null,
    currentPhoneNumber: null,
    wallet: null,
  },
});

const currentNumberState = atom({
  key: "currentNumber",
  default: "",
});

const subscriptionDetailsState = atom({
  key: "subscriptionDetails",
  default: "",
});
const numberState = atom({
  key: "numberState",
  default: "",
});
const countryState = atom({
  key: "countryState",
  default: "",
});
const amountState = atom({
  key: "amountState",
  default: "",
});
const binState = atom({
  key: "currentBin",
  default: {
    data: {},
  },
});

const numberDetailsState = atom({
  key: "numberEdit",
  default: {
    data: {},
  },
});

export {
  businessDataState,
  currentNumberState,
  binState,
  subscriptionDetailsState,
  numberDetailsState,
  numberState,
  amountState,
  countryState,
};
