import { getMode } from './auth';

export const SENDCHAMP_LOGO = process.env.REACT_APP_SENDCHAMP_LOGO;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const SANDBOX_API_BASE_URL = process.env.REACT_APP_SANDBOX_API_BASE_URL;
export const SANDBOX_BASE_URL = process.env.REACT_APP_STAGING_URL;
export const WHATSAPP_SANDBOX = process.env.REACT_APP_WHATSAPP_SANDBOX;

export const FLUTTERWAVE_PUBLIC_KEY =
  getMode === 'LIVE'
    ? process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY
    : process.env.REACT_APP_SANDBOX_FLUTTERWAVE_PUBLIC_KEY;

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const MONNIFY_API_KEY =
  getMode === 'LIVE'
    ? process.env.REACT_APP_MONNIFY_API_KEY
    : process.env.REACT_APP_SANDBOX_MONNIFY_API_KEY;

export const MONNIFY_CONTRACT_CODE =
  getMode === 'LIVE'
    ? process.env.REACT_APP_MONNIFY_CONTRACT_CODE
    : process.env.REACT_APP_SANDBOX_MONNIFY_CONTRACT_CODE;

export const SANDBOX_PASSWORD = process.env.REACT_APP_SANDBOX_PASSWORD;

export const DSN = process.env.REACT_APP_DSN;

export const CLOUDINARY_NAME = process.env.REACT_APP_CLOUDINARY_ACCOUNT;

export const BODSQUARE_URL = process.env.REACT_APP_BODSQUARE_URL;

export const SIMULATOR_URL = process.env.REACT_APP_SIMULATOR_URL;

export const LIVE_URL = process.env.REACT_APP_LIVE_URL;

export const DEVELOPER_HUB_URL = process.env.REACT_APP_DEVELOPER_HUB_URL;

export const API_DOCS_URL = process.env.REACT_APP_API_DOCUMENTATION_URL;

export const TELEGRAM_COMMUNITY_URL =
  process.env.REACT_APP_TELEGRAM_COMMUNITY_URL;

export const SLACK_CHANNEL = process.env.REACT_APP_SLACK_CHANNEL;

export const GOOGLE_RECAPTCHA = process.env.REACT_APP_RECAPTCHA;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const MIXPANEL = process.env.REACT_APP_MIXPANEL;

export const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

export const CREDIT_WALLET_MULTIPLIER = process.env.REACT_APP_CREDIT_WALLET_MULTIPLIER;

