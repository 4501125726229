import { request } from "../../utils";

export const getBusinessSenders = async ({ business_uid, page, per_page }) => {
  try {
    const url = `/sms/sms-sender/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createSender = async ({
  business_uid,
  name,
  sample,
  use_case,
}) => {
  try {
    const url = `/sms/sender`;
    const res = await request.post(url, {
      business_uid,
      name,
      sample,
      use_case,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
