import React from 'react';

const useNavMenu = () => {
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow(!show);

  return {
    show,
    toggleShow,
    setShow,
  };
};

export default useNavMenu;
