import { lazy } from "react";

const Login = lazy(() => import("../../pages/auth/Login"));
const Register = lazy(() => import("../../pages/auth/Register"));
const VerifyEmail = lazy(() => import("../../pages/auth/VerifyEmail"));
const ResetPassword = lazy(() => import("../../pages/auth/ResetPassword"));
const ChangePassword = lazy(() => import("../../pages/auth/ResetPassword/ChangePassword"));
// const Onboarding = lazy(() => import("../../pages/auth/Onboarding"));
const TeamWorkspace = lazy(() => import("../../pages/auth/Workspace"));

export const publicRoute = [
  {
    name: "Login",
    path: "/login",
    exact: true,
    component: Login,
    protected: false,
  },
  {
    name: "Register",
    path: "/signup",
    exact: true,
    component: Register,
    protected: false,
  },
  {
    name: "VerifyEmail",
    path: "/verify-email",
    exact: true,
    component: VerifyEmail,
    protected: false,
  },
  {
    name: "ForgotPassword",
    path: "/forgotPassword",
    exact: true,
    component: ResetPassword,
    protected: false,
  },
  {
    name: "ChangePassword",
    path: "/changePassword",
    exact: true,
    component: ChangePassword,
    protected: false,
  },
  // {
  //   name: 'Onboarding',
  //   path: '/onboarding',
  //   component: Onboarding,
  //   protected: false,
  //   exact: true,
  // },
  {
    name: 'Team Workspace',
    path: '/join-workspace',
    component: TeamWorkspace,
    protected: true,
    exact: true,
  },
   
];
