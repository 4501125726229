import { request } from '../../utils';

export const getCountries = async () => {
  try {
    const res = await request.get('/country');
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};


export const getCurrencies = async () => {
  try {
    const res = await request.get('/currencies');
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
