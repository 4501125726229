import { request } from "../../utils";

export const createEmailSubscription = async ({
  user_id,
  email_plan_id,
  business_uid,
}) => {
  try {
    const url = `/email/subscriptions`;
    const response = await request.post(url, {
      user_id,
      email_plan_id,
      business_uid,
    });

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailSubscription = async (business_uid) => {
  try {
    const url = `/email/subscriptions/${business_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailPlans = async (business_uid) => {
  try {
    const url = `/email/plans/list/${business_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
