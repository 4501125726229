import { request } from '../../../utils';

export const getEmailDomainSmtps = async domain_uid => {
  try {
    const url = `/email/domain/smtp/${domain_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailDomainSmtpById = async smtp_uid => {
  try {
    const url = `/email/smtp/${smtp_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createEmailDomainSmtp = async ({
  email_domain_auth_uid,
  name,
}) => {
  try {
    const url = `/email/create-smtp-details`;
    const response = await request.post(url, { email_domain_auth_uid, name });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateEmailDomainSmtp = async (
  smtp_uid,
  { domain_uid, name, provider_id }
) => {
  try {
    const url = `/email/smtp/${smtp_uid}`;
    const response = await request.put(url, { domain_uid, name, provider_id });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const deleteEmailDomainSmtp = async smtp_uid => {
  try {
    const url = `/email/smtp/${smtp_uid}`;
    const response = await request.delete(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
