import { ENV } from "../helpers";
import {
  HomeIcon,
  WalletIcon,
  EditIcon,
  AccountIcon,
  VoiceGrayIcon,
  EmailGrayIcon,
  SMSGrayIcon,
  WhatsappGrayIcon,
  OtpIcon,
  NumberIcon,
  SIPIcon,
  BetaIcon,
  NoDisplay,
  CampaignIcon,
  LinkIcon
} from "../assets/svg";

export const dashboardRoute = [
  {
    path: "/",
    match: "/",
    id: "main-dashboard",
    title: "Dashboard",
    className: "dashboard",
    beta: NoDisplay,
    icon: HomeIcon,
  },
  {
    title: "Channels",
    id: "channels",
    children: [
      {
        title: "SMS",
        id: "sms",
        beta: NoDisplay,
        icon: SMSGrayIcon,
        className: "sms",
        children: [
          {
            id: "sms-overview",
            match: "/channel/sms/overview",
            path: "/channel/sms/overview",
            title: "Overview",
          },

          {
            id: "sms-test",
            match: "/channel/sms/test",
            path: "/channel/sms/test",
            title: "Test SMS",
          },
          {
            id: "sms-sender-id",
            match: "/channel/sms/sender",
            path: "/channel/sms/sender",
            title: "Sender ID",
          },
          {
            id: "sms-reports",
            match: "/channel/sms/reports",
            path: "/channel/sms/reports",
            title: "Reports",
          },
        ],
      },
      {
        title: "Email",
        id: "email",
        beta: NoDisplay,
        icon: EmailGrayIcon,
        className: "email",
        children: [
          {
            id: "email-overview",
            match: "/channel/email/overview",
            path: "/channel/email/overview",
            title: "Overview",
          },
          // {
          //   id: "email-domain",
          //   match: "/channel/email/sender",
          //   path: "/channel/email/sender",
          //   title: "Email Sender Authentication",
          // },
          {
            id: "email-domain",
            match: "/channel/email/domain",
            path: "/channel/email/domain",
            title: "Domain Auth & SMTP",
          },
          {
            id: "email-sub",
            match: "/channel/email/subscription",
            path: "/channel/email/subscription",
            title: "Subscription",
          },
          {
            id: "email-reports",
            match: "/channel/email/reports",
            path: "/channel/email/reports",
            title: "Reports",
          },
        ],
      },
      {
        title: "Voice",
        id: "voice",
        beta: NoDisplay,
        icon: VoiceGrayIcon,
        className: "voice",
        children: [
          {
            id: "voice-overview",
            match: "/channel/voice/overview",
            path: "/channel/voice/overview",
            title: "Overview",
          },
          {
            id: "voice-test",
            match: "/channel/voice/test",
            path: "/channel/voice/test",
            title: "Test Voice",
          },
          {
            id: "voice-reports",
            match: "/channel/voice/reports",
            path: "/channel/voice/reports",
            title: "Reports",
          },
        ],
      },
      {
        title: "Whatsapp",
        id: "whatsapp",
        beta: NoDisplay,
        icon: WhatsappGrayIcon,
        className: "whatsapp",
        children: [
          {
            id: "whatsapp-overview",
            match: "/channel/whatsapp/overview",
            path: "/channel/whatsapp/overview",
            title: "Overview",
          },
          {
            id: "whatsapp-test",
            match: "/channel/whatsapp/test",
            path: "/channel/whatsapp/test",
            title: "Test Whatsapp",
          },
          {
            id: "whatsapp-activate",
            match: "/channel/whatsapp/activate-number",
            path: "/channel/whatsapp/activate-number",
            title: "Activate number",
          },
          {
            id: "whatsapp-template",
            match: "/channel/whatsapp/template",
            path: "/channel/whatsapp/template",
            title: "Template",
          },
          {
            id: "whatsapp-report",
            match: "/channel/whatsapp/reports",
            path: "/channel/whatsapp/reports",
            title: "Reports",
          },
        ],
      },
    ],
  },
  {
    title: "PRODUCTS",
    id: "products",
    children: [


      {
        title: "OTP Verification",
        id: "otp",
        beta: NoDisplay,
        icon: OtpIcon,
        className: "otp",
        children: [
          {
            id: "otp-overview",
            match: "/channel/otp/overview",
            path: "/channel/otp/overview",
            title: "Overview",
          },
          {
            id: "otp-test",
            match: "/channel/otp/test",
            path: "/channel/otp/test",
            title: "Test OTP",
          },
          {
            id: "otp-reports",
            match: "/channel/otp/reports",
            path: "/channel/otp/reports",
            title: "Reports",
          },
        ],
      },
      {
        title: "Number",
        id: "number",
        beta: BetaIcon,
        icon: NumberIcon,

        className: "number",
        children: [
          {
            id: "number-overview",
            match: "/channel/number/overview",
            path: "/channel/number/overview",
            title: "Overview",
          },
          // {
          //   id: "number-call",
          //   match: "/channel/number/call",
          //   path: "/channel/number/call",
          //   title: "Make A Phone Call",
          // },
          {
            id: "number-request",
            match: "/channel/number/request",
            path: "/channel/number/request",
            title: "Get number insight",
          },
          // {
          //   id: "number-purchase",
          //   match: "/channel/number/purchase",
          //   path: "/channel/number/purchase",
          //   title: "Buy number",
          // },
          // {
          //   id: "number-bin",
          //   match: "/channel/number/bin",
          //   path: "/channel/number/bin",
          //   title: "XML-Bin",
          // },
          // {
          //   id: "number-reports",
          //   match: "/channel/number/reports",
          //   path: "/channel/number/reports",
          //   title: "Reports",
          // },
        ],
      },
      // {
      //   title: "Campaigns",
      //   id: "campaigns",
      //   beta: LinkIcon,
      //   icon: CampaignIcon,

      //   className: "campaigns",
      //   children: [
      //     {
      //       id: "campaigns-endpoint",
      //       match: "/channel/campaigns/endpoint",
      //       path: "/channel/campaigns/endpoint",
      //       title: "campaigns Endpoint",
      //     },
      //     {
      //       id: "campaigns-reports",
      //       match: "/channel/campaigns/reports",
      //       path: "/channel/campaigns/reports",
      //       title: "Reports",
      //     },
      //   ],
      // },
      // {
      //   title: "SIP Trunking",
      //   id: "sip",
      //   beta: BetaIcon,
      //   icon: SIPIcon,

      //   className: "sip",
      //   children: [
      //     {
      //       id: "sip-endpoint",
      //       match: "/channel/sip/endpoint",
      //       path: "/channel/sip/endpoint",
      //       title: "SIP Endpoint",
      //     },
      //     {
      //       id: "sip-reports",
      //       match: "/channel/sip/reports",
      //       path: "/channel/sip/reports",
      //       title: "Reports",
      //     },
      //   ],
      // },
    ],
  },
  {
    path: ENV.BODSQUARE_URL,
    match: ENV.BODSQUARE_URL,
    id: "campaigns",
    title: "Campaigns",
    className: "campaigns",
    beta: LinkIcon,
    icon: CampaignIcon,
    isExternal: true
  },

  {
    title: "More",
    id: "more",
    children: [
      {
        title: "Wallet",
        id: "wallet",
        beta: NoDisplay,
        icon: WalletIcon,
        children: [
          {
            id: "wallet",
            match: "/wallet",
            path: "/wallet",
            title: "Wallet",
          },
          {
            id: "transaction",
            match: "/transaction",
            path: "/transaction",
            title: "Transaction",
          },
          {
            id: "pricing",
            match: "/pricing",
            path: "/pricing",
            title: "Pricing",
          },
        ],
      },
      {
        title: "Accounts",
        id: "accounts",
        beta: NoDisplay,
        icon: AccountIcon,
        children: [
          {
            id: "account-profile-settings",
            match: "/accounts/settings",
            path: "/accounts/settings",
            title: "Profile Settings",
          },
          {
            id: "account-teams",
            match: "/teams",
            path: "/teams",
            title: "Teammates",
          },
          // {
          //   id: "account-audit",
          //   match: "/audit",
          //   path: "/audit",
          //   title: "Audit history",
          // },
          {
            id: "account-business-profile",
            match: "/accounts/business-profile",
            path: "/accounts/business-profile",
            title: "Business Profile",
          },

          {
            id: "account-preference",
            match: "/accounts/general-preference",
            path: "/accounts/general-preference",
            title: "General Preference",
          },
          {
            id: "account-api-webhooks",
            match: "/accounts/api-webhooks",
            path: "/accounts/api-webhooks",
            title: "API keys & Webhooks",
          },

          {
            id: "dev-community",
            isExternal: true,
            path: ENV.TELEGRAM_COMMUNITY_URL,
            match: ENV.TELEGRAM_COMMUNITY_URL,
            className: "community",
            title: "Developer Community",
            beta: NoDisplay,
            icon: EditIcon,
          },
          {
            id: "accounts-deocumentation",
            isExternal: true,
            match: ENV.API_DOCS_URL,
            path: ENV.API_DOCS_URL,
            title: "Documentation",
            beta: NoDisplay,
            icon: EditIcon,
          },
        ],
      },
    ],
  },
];
