import axios from 'axios';
import { getAuthToken, ENV, clearStorage, getMode } from '../helpers';

const getBaseUrl = () => {
  if (getMode === 'LIVE') {
    return ENV.API_BASE_URL;
  } else if (getMode === 'TEST') {
    return ENV.SANDBOX_API_BASE_URL;
  } else {
    return ENV.API_BASE_URL;
  }
};

const options = {
  baseURL: getBaseUrl(),
  headers: {
    Accept: 'application/json,text/plain,*/*',
    'Content-Type': 'application/json',
  },
};

export const request = axios.create(options);

request.interceptors.request.use(
  config => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    try {
      const errorData = error?.response?.data;
      if (errorData?.message === 'Unauthenticated.' || error.response.status === 401) {
        clearStorage();
        window.location.reload();
      }
      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// SANDBOX CONFIG

const sandboxOptions = { ...options, baseURL: ENV.SANDBOX_API_BASE_URL };
export const sandboxRequest = axios.create(sandboxOptions);

sandboxRequest.interceptors.request.use(
  config => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// OPEN API CONFIG

const openAPIOptions = { ...options, baseURL: getBaseUrl() };
export const demoRequest = axios.create(openAPIOptions);
