import { request } from '../../../utils';

export const getEmailDnsByDomainId = async domain_uid => {
  try {
    const url = `/eemail/domain/dns/${domain_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
