import { extendTheme } from "@chakra-ui/react";

const CC = {
  fonts: {
    heading: '"Inter"',
    body: '"Inter",sans-serif',
    // heading:
    //   'K2D,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    // body: 'K2D,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "24px",
    xl: "32px",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: {
    brand: {
      50: "#F7FAFF",
      60: "#FAFCFF",
      70: "#F6FAFE",
      100: "#197EEF",
      150: "#197EEF1F",
      200: "#0066FF",
      800: "#001240",

      black: {
        100: "#13161D",
      },

      gray: {
        50: "#A3A5AA",
        100: "#A7A7A7",
        150: "#D3D4D6",
        200: "#E5E6E8",

        300: "#666975",
        350: "#6682A2",
        360: "#6682A20A",
        400: "#C2CDDA",
      },

      orange: {
        100: "#E16B61",
      },
    },
  },
};

export const CustomTheme = extendTheme({ ...CC });
