import { useState } from "react";
import { Flex, Text, Box, Link as ChakraLink } from "@chakra-ui/react";
import CustomButton from "../Button/Button";
import { CloseIcon } from "../../../assets/svg";
import { ENV } from "../../../helpers";

const NoticeBar = () => {
  const [dismissal, setDismissal] = useState(false);

  return (
    <>
      {!dismissal && (
        <Box h={["75px", "48px"]} pos={"relative"}>
          <Flex
            bg="brand.100"
            h="inherit"
            w="100%"
            justifyContent={"center"}
            zIndex={99}
            pos={"fixed"}
            transition="0.5s linear"
            px="3"
            py={[2, 0]}
          >
            <Flex alignItems={"center"} flexWrap={"wrap"}>
              <Text fontSize="sm" color="white" mr="3">
                Bodsquare by Sendchamp lets you create campaigns, monitor
                reports, and interact with customers from one app.
              </Text>
              <CustomButton
                text="Try it now!"
                w="77px"
                h="24px"
                bg="white"
                color="brand.100"
                fontSize="sm"
                fontWeight="500"
                rounded="4px"
                as={ChakraLink}
                href={ENV.BODSQUARE_URL}
              />

              <Box
                cursor={"pointer"}
                pos="absolute"
                top="2"
                right={2}
                onClick={() => {
                  setDismissal(true);
                }}
              >
                <CloseIcon color="white" />
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default NoticeBar;
