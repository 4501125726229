import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import mixpanel from "mixpanel-browser";

import { ENV } from "./helpers";
import { CustomTheme } from "./helpers/theme";
import { AppRouter } from "./routes/AppRouter";
import "./components/UI/CSS/style.css";
import ErrorBoundary from "./components/ErrorBoundary";

mixpanel.init(ENV.MIXPANEL);
const queryClient = new QueryClient();
function App() {
  return (
    
    <ErrorBoundary>
      <ChakraProvider theme={CustomTheme}>
        <CSSReset />
        <BrowserRouter>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </RecoilRoot>
        </BrowserRouter>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;
