import { useState, useEffect, useCallback } from "react";
import { Box, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";

import { useBusiness } from "../../hooks";
import CustomButton from "../UI/Button/Button";
import CustomInput from "../UI/Form/Input";
import CustomSelect from "../UI/Form/Select";
import {
  getDesignations,
  getIndustries,
  getCurrencies,
  getCountries,
  updateBusiness,
} from "../../services";

const BusinessForm = ({ activeBusiness = undefined }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const toast = useToast();
  const { getActiveBusiness, createBusiness, getWallet } = useBusiness();
  const [currencies, setCurrencies] = useState([]);
  const [allDesignation, setAllDesignation] = useState([]);
  const [allIndustries, setAllIndustries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const allReferrals = [
    {
      value: "Twitter",
      label: "Twitter",
    },
    {
      value: "Developer Community",
      label: "Developer Community",
    },
    {
      value: "Events",
      label: "Events",
    },
    {
      value: "Facebook",
      label: "Facebook",
    },
    {
      value: "Instagram",
      label: "Instagram",
    },
    {
      value: "Slack",
      label: "Slack",
    },
    {
      value: "Word Of Mouth",
      label: "Word Of Mouth",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];
  const [form, setForm] = useState({
    ...activeBusiness,
    user_uid: activeBusiness?.user.uid,
    country_id: activeBusiness && String(activeBusiness?.country_id),
    currency: activeBusiness?.currency_data?.alpha3,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const industries = await getIndustries();
      const designations = await getDesignations();
      const currencies = await getCurrencies();
      const countries = await getCountries();

      setAllIndustries(
        industries.map((industry) => ({
          value: industry.name,
          label: industry.name,
          name: industry.name,
        }))
      );

      setAllCountries(
        countries.map((country) => ({
          value: country.id,
          label: country.name,
          name: country.name,
        }))
      );

      setAllDesignation(
        designations.map((des) => ({
          value: des.name,
          label: des.name,
          name: des.name,
        }))
      );

      setCurrencies(
        currencies.map(({ currency, alpha3, name }) => ({
          value: alpha3,
          name: currency,
          label: `${currency} - ${name}`,
        }))
      );
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
    if (!location?.pathname === "/onboarding") {
      getActiveBusiness();
    }
  }, []);

  const handleBusinessUpdate = useMutation(updateBusiness, {
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Profile successfully updated",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

      getActiveBusiness();
      getWallet();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const handleCreateBusiness = async () => {
    setLoading(true);
    try {
      await createBusiness(form, true);
      // window.location.reload();
    } catch (error) {}
    setLoading(false);
  };
  return (
    <>
      <form>
        <Box>
          <FormControl mb="4">
            <FormLabel
              mb={0}
              htmlFor="country"
              color="brand.gray.300"
              fontSize="xs"
              fontWeight="500"
            >
              COMPANY NAME
            </FormLabel>

            <CustomInput
              type="text"
              defaultValue={form?.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              placeholder="Enter your company’s name"
              fontSize="sm"
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl mb="4" fontSize="sm">
            <FormLabel
              mb={0}
              htmlFor="country"
              color="brand.gray.300"
              fontSize="xs"
              fontWeight="500"
            >
              YOUR ROLE
            </FormLabel>

            <CustomSelect
              value={allDesignation.find(
                (des) => des.name === form?.designation
              )}
              isLoading={loading}
              options={allDesignation}
              handleInputSelect={(e) => {
                setForm({ ...form, designation: e.value });
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl mb="4">
            <FormLabel
              htmlFor="email"
              color="brand.gray.300"
              fontSize="xs"
              fontWeight="500"
            >
              PERSONAL PHONE NUMBER
            </FormLabel>
            <PhoneInput
              enableSearch={true}
              disableSearchIcon={true}
              inputClass="phone-input"
              buttonStyle={{ padding: "0 8px", background: "white" }}
              value={form?.phone_number}
              country="ng"
              disabled={activeBusiness || loading}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              onChange={(value) => {
                setForm({ ...form, phone_number: value });
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl mb="4" fontSize="sm">
            <FormLabel
              mb={0}
              htmlFor="country"
              color="brand.gray.300"
              fontSize="xs"
              fontWeight="500"
            >
              INDUSTRY
            </FormLabel>

            <CustomSelect
              value={allIndustries.find(
                (ind) => ind.name == form.industry_name
              )}
              isLoading={loading}
              options={allIndustries}
              handleInputSelect={(e) => {
                setForm({ ...form, industry_name: e.value });
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl mb="4" fontSize="sm">
            <FormLabel
              mb={0}
              htmlFor="country"
              color="brand.gray.300"
              fontSize="xs"
              fontWeight="500"
              textTransform={"uppercase"}
            >
              DEFAULT CURRENCY
            </FormLabel>
            <CustomSelect
              value={currencies.find((cur) => cur.value === form?.currency)}
              isLoading={loading}
              options={currencies}
              handleInputSelect={(e) => {
                setForm({ ...form, currency: e.value });
              }}
            />
          </FormControl>
        </Box>
        <Box>
          {location?.pathname === "/onboarding" ? (
            <FormControl mb="4" fontSize="sm">
              <FormLabel
                mb={0}
                htmlFor="country"
                color="brand.gray.300"
                fontSize="xs"
                fontWeight="500"
                textTransform={"uppercase"}
              >
                HOW DID YOU HEAR ABOUT US?
              </FormLabel>

              <CustomSelect
                fontSize="sm"
                options={allReferrals}
                handleInputSelect={(e) => {
                  setForm({ ...form, referral_channel_name: e.name });
                }}
              />
            </FormControl>
          ) : (
            <FormControl mb="4" fontSize="sm">
              <FormLabel
                mb={0}
                htmlFor="country"
                color="brand.gray.300"
                fontSize="xs"
                fontWeight="500"
                textTransform={"uppercase"}
              >
                country
              </FormLabel>

              <CustomSelect
                fontSize="sm"
                value={allCountries.find(
                  (cou) => cou.value == form?.country_id
                )}
                isLoading={loading}
                options={allCountries}
                handleInputSelect={(e) => {
                  setForm({ ...form, country_id: String(e.value) });
                }}
              />
            </FormControl>
          )}
        </Box>
      </form>

      <CustomButton
        text={activeBusiness ? "Update profile" : "Create business"}
        loading={handleBusinessUpdate.isLoading || loading}
        disabled={
          handleBusinessUpdate.isLoading ||
          loading ||
          !form.currency ||
          !form.designation ||
          !form.name ||
          !form.phone_number ||
          !form.industry_name
        }
        onClick={() => {
          activeBusiness
            ? handleBusinessUpdate.mutateAsync(form)
            : handleCreateBusiness();
        }}
      />
    </>
  );
};

export default BusinessForm;
