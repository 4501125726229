import { request } from "../../utils";

export const deleteNumberBin = async (bin_uid) => {
  try {
    const url = `/bin/${bin_uid}`;
    const res = await request.delete(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createNumberBin = async (payload) => {
  try {
    const url = `/bin`;
    const res = await request.post(url, payload);
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const updateNumberBin = async (payload) => {
  try {
    const url = `/bin/${payload?.bin_uid}`;
    const res = await request.put(url, {
      business_uid: payload?.business_uid,
      name: payload?.name,
      xml_content: payload?.xml_content,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getNumberBin = async ({ page = 1, per_page, business_uid }) => {
  try {
    const url = `/bin/list/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const searchNumber = async ({
  page = 1,
  per_page,
  capabilities,
  type,
  country_code,
}) => {
  try {
    const url = `/number`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
        capabilities,
        type,
        country_code,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBusinessPhoneNumbers = async ({
  page = 1,
  per_page,
  business_uid,
}) => {
  try {
    const url = `/number/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getNumberSubscription = async ({
  business_uid,
  number_uid,
  page = 1,
  per_page,
}) => {
  try {
    const url = `/number/subscription/${business_uid}/${number_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const buyNumber = async ({ number_uid, business_uid }) => {
  try {
    const url = `/number`;
    const res = await request.post(url, { number_uid, business_uid });
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const editNumber = async ({
  number_uid,
  business_uid,
  webhook_url,
  webhook_method,
}) => {
  try {
    const url = `/number`;
    const res = await request.put(url, {
      number_uid,
      business_uid,
      webhook_url,
      webhook_method,
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};
export const getNumberReportStatistics = async ({
  start_date,
  end_date,
  timeline,
  phone_number,
  business_uid,
}) => {
  try {
    const url = `/number/statistics/${business_uid}`;
    const response = await request.get(url, {
      params: { phone_number, start_date, end_date, timeline },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSingleNumberReport = async (number_uid) => {
  try {
    const url = `/number/status/report/${number_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getNumberReport = async ({
  page,
  timeline,
  start_date,
  end_date,
  business_uid,
  perPage,
  phone_number,
  isfilter = false,
  action,
}) => {
  try {
    const url = `/number/report/${business_uid}`;
    const response = await request.get(url, {
      params: {
        page,
        phone_number,
        timeline,
        start_date,
        end_date,
        perPage,
        isfilter,
        action,
      },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const ToggleSubscription = async ({
  business_uid,
  number_uid
}) => {
  try {
    const url = `/number/activate/toggle`;
    const res = await request.put(url, {
      business_uid,
      number_uid
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};