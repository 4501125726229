import { request } from '../../utils';

export const getIndustries = async () => {
  try {
    const res = await request.get('/industries');
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
