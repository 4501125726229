import { request } from "../../../utils";

export const getEmailDomainSenders = async ({ business_uid, per_page }) => {
  try {
    const url = `/email/sender/${business_uid}`;
    const response = await request.get(url, {
      params: { per_page },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailDomainSenderById = async (sender_uid) => {
  try {
    const url = `/email/sender/${sender_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createEmailDomainSender = async ({
  business_uid,
  name,
  email,
  nickname,
  reply_to,
  reply_to_name,
  address,
  city,
  country,
  state,
  zip,
}) => {
  try {
    const url = `/eemail/create-email-sender`;
    const response = await request.post(url, {
      business_uid,
      name,
      email,
      nickname,
      reply_to,
      reply_to_name,
      address,
      city,
      country,
      state,
      zip,
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailDomainSender = async () => {
  try {
    const url = `/email/sender`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailDomainSendersByBusiness = async (business_uid) => {
  try {
    const url = `/email/senders/${business_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateEmailDomainSender = async (
  sender_uid,
  {
    domain_uid,
    name,
    email,
    nickname,
    reply_to,
    reply_to_name,
    address,
    city,
    country,
  }
) => {
  try {
    const url = `/email/sender/${sender_uid}`;
    const response = await request.put(url, {
      domain_uid,
      name,
      email,
      nickname,
      reply_to,
      reply_to_name,
      address,
      city,
      country,
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const deleteEmailDomainSender = async (sender_uid) => {
  try {
    const url = `/email/sender/${sender_uid}`;
    const response = await request.delete(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
