import { sandboxRequest, request } from "../../utils";
import { ENV } from "../../helpers";

export const getUserProfile = async () => {
  try {
    const res = await request.get(`/auth/get-user`);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getUserById = async (userId) => {
  try {
    const res = await request.get(`/user/${userId}`);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getDashboardStat = async ({ business_uid, timeline }) => {
  try {
    const res = await request.get(`/dashboard/${business_uid}`, {
      params: {
        timeline,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const loginUser = async (mode = "LIVE", { email, password }) => {
  try {
    const res =
      mode === "LIVE"
        ? await request.post("/auth/login", { email, password })
        : await sandboxRequest.post("/auth/login", {
            email,
            password: ENV.SANDBOX_PASSWORD,
          });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const googleAuth = async ({
  email,
  first_name,
  last_name,
  provider_user_id,
  provider_uid,
}) => {
  try {
    const res = await request.post(`/auth/login/${provider_uid}/callback`, {
      email,
      first_name,
      last_name,
      provider_user_id,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getProviders = async () => {
  try {
    const res = await request.get("/provider");
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getUsers = async (business_uid) => {
  try {
    const res = await request.get("/users", {
      params: {
        business_uid,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const registerUser = async (values) => {
  try {
    const res = await request.post("/auth/register", values);
    return res?.data;
  } catch (error) {
    if (error?.response?.data?.data) {
      throw new Error(Object.values(error.response.data.data).join(", "));
    }
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const verifyUserEmail = async (verification_token) => {
  try {
    const res = await request.post("/auth/email/verify", null, {
      params: {
        verification_token,
      },
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const resendUserVerificationEmail = async ({ email, base_url }) => {
  try {
    const res = await request.post("/auth/resend-token", { email, base_url });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const resetPassword = async ({
  token,
  password,
  password_confirmation,
}) => {
  try {
    const res = await request.post("/password/reset", {
      token,
      password,
      password_confirmation,
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const forgotPassword = async ({ email, reset_url }) => {
  try {
    const res = await request.post("/password/forgot", {
      email,
      reset_url,
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const changePassword = async (values) => {
  try {
    const res = await request.post("/password/change", values);
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createUser = async (values) => {
  try {
    const res = await request.post("/user", values);
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateUser = async (userId, values) => {
  try {
    const res = await request.patch(`/user/${userId}`, values);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
