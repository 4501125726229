import axios from "axios";
import { demoRequest, request } from "../../utils";


export const sendDemoSms = async (
  token,
  { to = [], message, sender_name, route, country_code }
) => {
  try {
    const url = `/sms/send`;
    const response = await demoRequest.post(
      url,
      {
        to,
        message,
        sender_name,
        route,
        country_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const makePhoneCall = async (
  token,
  { from_phone_number, to_phone_number, direction}
) => {
  try {
    const url = `/number-voice`;
    const response = await demoRequest.post(
      url,
      {
        from_phone_number,
        to_phone_number,
        direction
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getNumberInsight = async (
  token,
  {phone_number, type }
) => {
  try {
    const url = `/number-insights/check`;
    const response = await demoRequest.post(
      url,
      {
       phone_number,
       type
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const sendDemoVoiceMessage = async (
  token,
  {
    customer_mobile_number,
    message = undefined,
    type,
    business_uid,
    repeat,
    path = undefined,
    country_code,
  }
) => {
  try {
    const url = `/voice/send`;
    const response = await demoRequest.post(
      url,
      {
        customer_mobile_number,
        message,
        type,
        business_uid,
        repeat,
        path,
        country_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const sendDemoOTP = async (
  token,
  {
    channel,
    sender,
    token_type,
    token_length,
    expiration_time,
    customer_mobile_number,
    customer_email_address,
    meta_data = { description: "demo" },sender_email_address
  }
) => {
  try {
    const url = `/verification/create`;
    const response = await demoRequest.post(
      url,
      {
        channel,
        sender,
        token_type,
        token_length,
        expiration_time,
        customer_mobile_number,
        customer_email_address,
        meta_data,
        in_app_token: false,sender_email_address
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const sendDemoWhatsappMessage = async (
  token,
  {
    // sandbox_message_id,
    recipient,
    sender,
    type,
    // link,
    message,
    // business_uid,
    template_code,
    custom_data,
  }
) => {
  try {
    const url = `/whatsapp/message/send`;
    const response = await demoRequest.post(
      url,
      {
        recipient,
        sender,
        type,
        // link,
        message,
        // business_uid,
        template_code,
        custom_data,
        // sandbox_message_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const sendDemoQuickWhatsappMessage = async (
  token,
  {
    type,
    // link,
    message,
    template_code,
    custom_data,
    recipient,
    sender,
    // dial_code,
    // template_uid,
  }
) => {
  try {
    const url = `/whatsapp/send`;
    const response = await demoRequest.post(
      url,
      {
        // dial_code,
        type,
        // link,
        message,
        template_code,
        custom_data,
        recipient,
        sender,
        // template_uid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDemoWhatsappConversation = async (
  token,
  { phone_number, code }
) => {
  try {
    const url = `/whatsapp/message/${phone_number}/${code}`;
    const response = await demoRequest.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getDemoWhatsappCustomerPhoneNumber = async (
  token,
  { sandbox_uid }
) => {
  try {
    const url = `/whatsapp/message/phone_number/${sandbox_uid}`;
    const response = await demoRequest.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

// const reference = {
//   first_name,
//   last_name,
//   email,
// };

// const businessPayload = {
//   business_uid: '4b5f8e40-24e5-40c9-8ab5-d6a994b3cbb0',
//   is_first_time_buyer: false, 
// };


export const handleVerification = async (
  {
    reference, business_uid
  }
) => {
  try {
    const url = `/number/verify`;
    const response = await request.post(
      url,
      {
        reference, business_uid,
      },
    );

    localStorage.setItem('verificationReference', JSON.stringify(reference))

    return response;
    
  } catch (error) {
    throw error;
  }
};