import React from "react";
import {
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Drawer,
  DrawerBody,
  DrawerContent,
  useDisclosure,
  FormControl,
  FormLabel,
  Switch,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { RiMenuLine } from "react-icons/ri";
import { BellIcon, HelpIcon, MaskUserIcon } from "../../../assets/svg";
import { useRecoilState } from "recoil";
import { authDataState } from "../../../atoms/auth";
import { useAuth } from "../../../hooks";
import Sidebar from "./Sidebar";
import { ENV } from "../../../helpers";
import { tourState } from "../../../atoms/tour";
import { useLocation, useNavigate } from 'react-router-dom';
import CustomText from "../Text/Text.component";
import { dashboardRoute } from "../../../constant/dashboardRoutes";



const TopNav = () => {
  const [user] = useRecoilState(authDataState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { logout } = useAuth();
  const [tour, setTour] = useRecoilState(tourState);
  const {pathname} = useLocation()

  // console.log(user.userData)

  return (
    <Flex
      bg="white"
      h="62.5px"
      w="100%"
      pos={"relative"}
      justifyContent={["space-between", "space-between", "flex-end"]}
      alignItems={"center"}
      px={[4, "25px"]}
    >
        {/* <p>tyvugibuibd</p> */}
        {pathname.includes('/channel/number/') ? 
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Number'}
        </CustomText>
        :
        pathname.includes('/channel/email/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Email'}
        </CustomText>
        :
        pathname.includes('/channel/voice/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Voice'}
        </CustomText>
        :
        pathname.includes('/channel/sms/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'SMS'}
        </CustomText>
        :
        pathname.includes('/channel/whatsapp/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Whatsapp'}
        </CustomText>
        :
        pathname.includes('/channel/otp/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'OTP Verification'}
        </CustomText>
        :
        pathname.includes('/wallet')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Wallet'}
        </CustomText>
        :
        pathname.includes('/pricing')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Pricing'}
        </CustomText>
        :
        pathname.includes('/channel/sip/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'SIP Trunking'}
        </CustomText>
        : 
        pathname.includes('/')
        ?
        <CustomText
        position = 'absolute'
        left = '0'
        padding = '2em'
        fontWeight={'500'}
        fontSize ={'18px'}
        lineHeight = {'24px'}
        >
          {'Dashboard'}
        </CustomText>
        : 
        ''
        }



         {/* {dashboardRoute.map((route) => (
           pathname.includes(`/channel/${route.id}/`)
           ?
           <CustomText
           position = 'absolute'
           left = '0'
           padding = '2em'
           fontWeight={'500'}
           fontSize ={'18px'}
           lineHeight = {'24px'}
           >
            {route.title}
           </CustomText>
           :
           ''         
         ))
        } */}
     


    <IconButton
        display={["flex", "flex", "none"]}
        ref={btnRef}
        onClick={onOpen}
        bg="white"
        p="0"
        color="brand.gray"
        height="auto"
        my="auto"
      >
        <RiMenuLine />
      </IconButton>
      <Flex alignItems="center">
        <FormControl display="flex" alignItems="center" className="retakeTour">
          <FormLabel
            htmlFor="email-alerts"
            mb="0"
            textTransform={"uppercase"}
            color="brand.gray.350"
          >
            Tour
          </FormLabel>
          <Switch
            id="email-alerts"
            isChecked={tour.isOpen}
            onChange={(e) => {
              setTour({ ...tour, isOpen: e.target.checked });
            }}
          />
        </FormControl>
        <Text
          cursor="pointer"
          ml="3"
          onClick={() => {
            window.gist.chat("open");
          }}
        >
          <HelpIcon />
        </Text>
        <Text mx="3" cursor="pointer">
          <BellIcon />
        </Text>

        <Menu>
          <MenuButton mb="0" pb="0" className="community">
            <Flex alignItems="center" cursor="pointer">
              <MaskUserIcon />
              <Text color="brand.black.100" fontSize={"md"}>
                {user?.userData?.first_name}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList
            borderColor="brand.150"
            border="0.5px"
            fontSize={"sm"}
            w="194px"
            color="brand.gray.350"
            py="0"
            boxShadow={"0px 0px 24px rgba(25, 126, 239, 0.04)"}
          >
            <MenuItem py="2" as={ReachLink} to="/accounts/settings">
              Account settings
            </MenuItem>
            <MenuItem py="2" as={ReachLink} to="/wallet">
              Wallet
            </MenuItem>
            <MenuItem py="2" as={ReachLink} to="/accounts/api-webhooks">
              API & Integrations
            </MenuItem>
            <MenuItem
              py="2"
              as={ChakraLink}
              href={ENV.TELEGRAM_COMMUNITY_URL}
              target="_blank"
              textDecor={"none"}
              className="community"
            >
              Developer commnuity
            </MenuItem>
            <MenuItem
              py="2"
              as={ChakraLink}
              href={ENV.API_DOCS_URL}
              target="_blank"
              textDecor={"none"}
            >
              Documentation
            </MenuItem>
            <MenuItem
              py="3"
              px="16px"
              borderTopColor="brand.150"
              borderTopWidth="0.5px"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        boxShadow={"0px 0px 24px rgba(25, 126, 239, 0.04)"}
      >
        <DrawerContent p="0">
          <DrawerBody p="0">
            <Sidebar isOpen={true} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default TopNav;
