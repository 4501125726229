import React from "react";
import Select,{ components, DropdownIndicatorProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { ArrowDownThickIcon } from "../../../assets/svg";

const animatedComponents = makeAnimated();

const CustomSelect = ({
  handleInputSelect,
  options,
  isMulti,
  value,
  disabled,
  isLoading,
  defaultValue,
  addNew,
  placeholder,
  height = "44px",
  fontSize,
  components,
  ...rest
}) => {
  const DropdownIndicator = (
    props
  ) => {
    return (
      <components {...props}>
        <ArrowDownThickIcon />
      </components>
    );
  };
  const customStyles = {
    input: (provided, state) => ({
      ...provided,
     
      margin: 0,
      color: "#6682A2",
      
    }),

    placeholder: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
      color: "#6682A2",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      padding:0,
      margin: 0,
    }),

    control: (provided, state) => ({
      ...provided,
      border: "1px solid #E2E8F0",
      color: "#6682A2",
      padding: "0px 8px",
      borderRadius: "6px",
      height: height,
      fontSize: fontSize,
    }),

    container: (provided, state) => ({
      ...provided,
      height: "100%",
      color: "#6682A2",
      margin: "4px 0",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };

  return (
    <div>
      {addNew ? (
        <CreatableSelect
          placeholder={placeholder}
          value={value}
          isDisabled={disabled}
          isLoading={isLoading}
          components={components}
          onChange={handleInputSelect}
          options={options}
          defaultValue={
            isMulti
              ? defaultValue
              : options?.find((option) => option.value === defaultValue)
          }
          // onCreateOption={addNew}
          styles={customStyles}
          isMulti={isMulti}
        />
      ) : (
        <Select
          placeholder={placeholder}
          value={value}
          components={{DropdownIndicator}}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={handleInputSelect}
          options={options}
          defaultValue={
            isMulti
              ? defaultValue
              : options?.find((option) => option.value === defaultValue)
          }
          styles={customStyles}
          isMulti={isMulti}
          {...rest}
        />
      )}
    </div>
  );
};

export default CustomSelect;
