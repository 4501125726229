import { Text, Flex, Box } from "@chakra-ui/react";
import CustomButton from "../Button/Button";
import { GlobalSearchIcon } from "../../../assets/svg";

const BrokenPage = () => {

  return (
    <Flex
      flexDirection={"column"}
      alignitem="center"
      justifyContent="center"
      minH="100vh"
      bg="#FAFCFF"
    >
      <Box w="248px" mx="auto">
        <Flex justifyContent="center">
          <GlobalSearchIcon />
        </Flex>
        <Text
          mb="20"
          color="#6682A2"
          fontWeight={"500"}
          textAlign="center"
          fontSize="24px"
          fontFamily={"Inter"}
        >
          Somethings seems to have broken
        </Text>
        <CustomButton
          bg="#197EEF"
          text="Report Issue"
          cursor="pointer"
          onClick={() => {
            // window
          }}
        />
      </Box>
    </Flex>
  );
};

export default BrokenPage;
