import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../../hooks";
import { getAuthToken } from "../../helpers";
import Layout from "../../components/Layout";
import { getBusinessPhoneNumbers, handleVerification } from "../../services";
import { useBusiness } from "../../hooks";

// const { state } = useBusiness();
// const activeBusiness = state.activeBusiness;

export const ProtectedRoute = () => {
  const { state } = useAuth();
  const { pathname } = useLocation();
  // const navigate = useNavigate()

  const [numbers, setNumbers] = useState([]);

  const activeBusiness = useBusiness().state.activeBusiness;

  // const [isVerified, setIsVerified] = useState(false);

  const getPurchaseNumber = async () => {
    try {
      const response = await getBusinessPhoneNumbers({
        business_uid: activeBusiness.uid,
        // ...pagination
      });
      setNumbers(response);
    } catch (err) {}
  };

  // const checkVerification = async () => {
  //   const verificationReference = localStorage.getItem('verificationReference')
  //   try {
  //     const response = await handleVerification();
  //     console.log(response)
  //     setIsVerified(response.data.is_verified);
  //     if (verificationReference) {
  //       setIsVerified(true);
  //     }
  //   } catch (error) {
  //     setIsVerified(false);
  //   }
  // };

  useEffect(() => {
    getPurchaseNumber();
    // checkVerification();
  }, []);

  if (!getAuthToken()) {
    return <Navigate to="/login" />;
  } else if (
    state?.userData?.businesses?.length === 0 ||
    !state?.userData?.businesses
  ) {
    return <Navigate to="/onboarding" />;
  }

  if (
    (numbers?.data?.length === undefined &&
      pathname === "/channel/number/request/search") === true
  ) {
    return <Navigate to="/channel/number/purchase" />;
  }
  // else if(
  //   ( numbers.length > 0 ) && pathname.includes('/channel/number/request/search')
  // ){
  //   return <Navigate to = '/channel/number/request/search'/>
  // }

  return (
    <>
      <Layout />
    </>
  );
};
