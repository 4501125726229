import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Text,
  Box,
  useToast,
  Link as ChakraLink,
  Image,
  HStack,
  SimpleGrid,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useBusiness } from "../../hooks";
// import Logo from '../../../assets/logo.png';
import { setActiveBusinessUid } from "../../helpers";
import { ENV } from "../../helpers";
import { CheckIcon } from "../../assets/svg";
import CustomButton from "../UI/Button/Button";

const SwitchBusiness = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState();

  const {
    state: { businesses },
  } = useBusiness();

  const handleSelectBusiness = async () => {
    setLoading(true);

    try {
      setActiveBusinessUid(selectedBusiness?.uid);

      toast({
        title: "Success",
        description: "You have successfully selected a business",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  };

  return (
    <Flex minH="100vh" bg="brand.50" position="fixed" w="100%">
      <Box
        bgColor="#FCFCFC"
        margin="auto"
        width={["95%", "80%", "60%", "440px"]}
        maxWidth="800px"
        borderRadius="6px"
        borderWidth={".5px"}
        borderColor="#197EEF1F"
        p="8"
        color="#000"
      >
        <Image src={ENV.SENDCHAMP_LOGO} w="156px" mx={"auto"} mb="4" />
        <Box spacing={3} my="10">
          <Text fontWeight="500" color="brand.black.100" fontSize="20px" mb="1">
            Select a business
          </Text>
          <Text fontSize="xs" color="brand.gray.300" w="70%">
            You currently have multiple businesses on our platform, kindly
            select one to Login.
          </Text>
        </Box>
        <SimpleGrid
          spacing="2"
          columns={{ base: 1, md: 1 }}
          mt="4"
          maxHeight="400px"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": {
              width: "0.1px",
            },
          }}
        >
          <Text fontSize="xs" fontWeight="500" color="brand.gray.300">
            BUSINESSES
          </Text>
          {businesses.map((business, index) => (
            <Flex
              key={business.uid}
              onClick={() => {
                setSelectedBusiness(business);
              }}
              color="brand.black.100"
              as="button"
              textAlign="left"
              alignItems="center"
              justifyContent={"space-between"}
              cursor={loading ? "not-allowed" : "pointer"}
              disabled={loading}
              borderRadius="6px"
              px="3"
              h="72px"
              border={
                business.uid === selectedBusiness?.uid
                  ? " 1px solid #0080FC"
                  : "1px solid #197EEF1F"
              }
              pos="relative"
              transition="all 0.2s"
              _hover={{
                border: "1px solid #0080FC",
              }}
            >
              <Flex alignItems={"center"}>
                <Box w="40px">
                  <Flex
                    w="40px"
                    h="40px"
                    rounded="full"
                    bg="#197EEF0A"
                    color="#197EEF"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontWeight="500">B{index + 1}</Text>
                  </Flex>
                </Box>
                <Box w="100%" ml="3">
                  <Text fontWeight="500">{business.name}</Text>
                </Box>
              </Flex>
              <Flex
                h="5"
                w="5"
                // pos="absolute"
                // top="20px"
                // right="20px"
                rounded="full"
                border="1px solid #197EEF1F"
                bg={business.uid === selectedBusiness?.uid && "#197EEF"}
                alignItems="center"
                justifyContent="center"
              >
                <CheckIcon />
              </Flex>
            </Flex>
          ))}

          <CustomButton
            text="Proceed"
            loading={loading}
            disabled={!selectedBusiness}
            onClick={handleSelectBusiness}
          />
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default SwitchBusiness;
