import { atom } from 'recoil';

const tourState = atom({
  key: 'tour',
  default: {
    isOpen: false,
    steps: [
      {
        selector: ".dashboard",
        content: "See an overview of all messages you have sent",
      },
      {
        selector: ".sms",
        content:
          "Send SMS to your customer and view reports of your SMS messages      ",
      },
      {
        selector: ".voice",
        content:
          "Make calls your customers and view reports of your Voice calls      ",
      },
      {
        selector: ".email",
        content:
          "Send Emails to your customers here. Authenticate your sender or domain and view reports      ",
      },
      {
        selector: ".whatsapp",
        content: "Activate your WhatsApp number and send WhatsApp messages to your customers and view reports.",
      },
      {
        selector: ".otp",
        content: "send OTP via  any of SMS, VOICE, WHATSAPP and EMAIL channels.",
      },
      {
        selector: ".documentation",
        content:
          "Our easy to use API Reference and documentation can be found here.",
      },
      // {
      //   selector: ".community",
      //   content:
      //     "Join other developers in our community here. Ask questions and get supported.",
      // },
      {
        content: 'Click this button to retake the tour',
        selector: '.retakeTour',
      },
    ],
  },
});

export { tourState };
