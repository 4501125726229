import React from "react";
import { Box } from "@chakra-ui/react";
import { Outlet, Navigate } from "react-router-dom";
import { getAuthToken } from "../../helpers";

export const PublicRoute = () => {
  return getAuthToken() ? (
    <Navigate to="/" />
  ) : (
    <Box bg="brand.50">
      <Outlet />
    </Box>
  ); 
};
