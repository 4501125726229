import { useNavigate, Link as ReachLink } from "react-router-dom";
import {
  Text,
  Flex,
  Box,
  UnorderedList,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { NotFoundIcon } from "../../../assets/svg";

const NotFound = () => {
  const navigate = useNavigate();
  const routeList = [
    {
      name: "Dashboard",
      path: "/",
    },
    {
      name: "SMS",
      path: "/channel/sms/overview",
    },
    {
      name: "Voice",
      path: "/channel/voice/overview",
    },
    {
      name: "Whatsapp",
      path: "/channel/whatsapp/overview",
    },
  ];

  return (
    <Flex
      flexDirection={"column"}
      alignitem="center"
      justifyContent="center"
      minH="100vh"
      bg="#FAFCFF"
    >
      <Box w="440px" mx="auto">
        <Flex justifyContent="center">
          <NotFoundIcon />
        </Flex>
        <Text
          color="brand.black.100"
          fontSize={"20px"}
          fontWeight={"600"}
          textAlign="center"
          mt="5"
          mb="3"
        >
          Page not found...
        </Text>
        <Text mb="5" color="#6682A2" fontWeight={"500"} textAlign="center">
          It looks like we couldn't find the the page you were looking for. You
          can go{" "}
          <Text
            color="brand.100"
            as="span"
            cursor={"pointer"}
            onClick={() => {
              navigate(-1);
            }}
          >
            back
          </Text>
          , or select one of the navigations to redirect to.
        </Text>
        <UnorderedList color="brand.100" fontWeight={500}>
          {routeList?.map((list) => {
            return (
              <ListItem
                display={"block"}
                as={ReachLink}
                to={list.path}
                textDecoration={"underline"}
                mb="3"
              >
                <ListIcon />
                {list.name}
              </ListItem>
            );
          })}
        </UnorderedList>
      </Box>
    </Flex>
  );
};

export default NotFound;
