import React from "react";

// CHakra
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Box,
  Flex,
  Divider,
} from "@chakra-ui/react";

const CustomModal = ({
  disclosure,
  children,
  Title = undefined,
  clear = () => {},
  boredrRadius,
  size = "xl",
  isCentered = false,
  closeAble = false,
  bgColor,
  color,
  onClose,
  closeTitle = "Cancel",
  ...props
}) => {
  return (
    <Modal
      isCentered={isCentered}
      size={size}
      isOpen={disclosure.isOpen}
      onClose={() => {
        // onClose();
        clear();
        disclosure?.onClose();
      }}
      {...props}
      mx="0"
      my="0"
    >
      <ModalOverlay >
        <ModalContent
          boxShadow={"none"}
          borderRadius="4px"
          color={color}
          bg={bgColor}
        >
          <Box mb={5} pt="32px" pb="6">
            <Flex flexDir="column">
              <Box px="40px">
                <Flex alignItems="center" justifyContent={Title ? "space-between" : 'end'}>
                  {Title && <Title />}
                  {closeAble && (
                    <Button
                      bg="transparent"
                      fontSize="sm"
                      // justifyContent={ !Title && 'end'}
                      fontWeight="400"
                      color="brand.100"
                      _focus={{ outline: "none", bg: "transparent" }}
                      _active={{ outline: "none" }}
                      _hover={{ bg: "transparent" }}
                      textDecor="underline"
                      onClick={() => {
                        disclosure.onClose();
                        onClose();
                      }}
                    >
                      {closeTitle}
                    </Button>
                  )}
                </Flex>
                <Divider
                  orientation="horizontal"
                  borderColor={"brand.gray.360"}
                  mb="24px"
                />
              </Box>

              <ModalBody px="40px">{children}</ModalBody>
            </Flex>
          </Box>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default CustomModal;
