import { request } from '../../utils';

export const getTemplateTypes = async () => {
  try {
    const url = `/whatsapp/template-type`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
