import { request } from "../../utils";

export const createBusiness = async (values) => {
  try {
    const res = await request.post("/business", values);
    return res?.data?.data;
  } catch (error) {
    if (error?.response?.data?.data) {
      throw new Error(Object.values(error.response.data.data).join(", "));
    }
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateBusiness = async ({
  uid,
  country_id,
  industry_name,
  name,
  currency,
  phone_number,
  designation,
  user_uid,
}) => {
  try {
    const url = `/business/${uid}`;
    const res = await request.patch(url, {
      country_id,
      industry_name,
      name,
      currency,
      phone_number,
      designation,
      user_uid,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBusiness = async (business_uid) => {
  try {
    const url = `/business/${business_uid}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getAllBusinesses = async () => {
  try {
    const res = await request.get(`/business`);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const toggleBusinessStatus = async (businessId) => {
  try {
    const url = `/business/toggle-status/${businessId}`;
    const res = await request.post(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBusinessConfig = async (businessId) => {
  try {
    const url = `/business-config/business/${businessId}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateBusinessConfig = async (
  business_config_uid,
  { callback_url, webhook_url }
) => {
  try {
    const url = `/business-config/business/${business_config_uid}`;
    const res = await request.patch(url, { callback_url, webhook_url });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const configBusinessSMSSender = async ({
  business_uid,
  show_sms_sender,
}) => {
  try {
    const url = `/business-config/business/${business_uid}`;
    const res = await request.patch(url, {
      show_sms_sender,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
