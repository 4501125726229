import { request } from "../../../utils";

export const getEmailDomains = async ({ business_uid, page,per_page }) => {
  try {
    const url = `/eemail/domain/business/${business_uid}`;
    const response = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailDomainById = async (domain_uid) => {
  try {
    const url = `/email/domain/${domain_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createEmailDomain = async ({
  domain,
  business_uid,
}) => {
  try {
    const url = `/eemail/create-email-domain`;
    const response = await request.post(url, {
      domain,
      business_uid,
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateEmailDomain = async (domain_uid, { business_uid, name }) => {
  try {
    const url = `/email/domain/${domain_uid}`;
    const response = await request.put(url, { business_uid, name });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const validateEmailDomain = async (domain_uid) => {
  try {
    const url = `/eemail/domain/verify/${domain_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const deleteEmailDomain = async (domain_uid) => {
  try {
    const url = `/eemail/domain/${domain_uid}`;
    const response = await request.delete(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailSubAccount = async (business_uid) => {
  try {
    const url = `/email/sub-account/${business_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailProviders = async () => {
  try {
    const url = `/email/providers`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createEmailSubAccount = async ({
  email_provider_uid,
  username,
  business_uid,
  email,
}) => {
  try {
    const url = `/email/sub-account`;
    const response = await request.post(url, {
      email_provider_uid,
      username,
      business_uid,
      email,
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export * from "./sender";
export * from "./smtp";
export * from "./dns";
