import React from "react";
import {
  Box,
  Text,
  Flex,
  useToast,
  Link as ChakraLink,
  useDisclosure,
} from "@chakra-ui/react";
// Components
import { Link as ReachLink } from "react-router-dom";
import { setActiveBusinessUid, getActiveBusinessUid } from "../../../helpers";
import { useBusiness } from "../../../hooks";
import useNavMenu from "./useNavMenu";
import { ArrowDownIcon, ArrowUpIcon, RencarIcon } from "../../../assets/svg";
import AddNewBusiness from "../../AddBusiness";

const Rencar = () => {
  const { show, toggleShow } = useNavMenu();
  const toast = useToast();
  const addBusinessDisclosure = useDisclosure();
  const {
    state: { businesses },
  } = useBusiness();
  const business = businesses.find(
    (business) => business.uid === getActiveBusinessUid()
  );
  const handleSelectBusiness = async (selectedBusiness) => {
    try {
      setActiveBusinessUid(selectedBusiness);
      toast({
        title: "Success",
        description: "You have successfully selected a business",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <Box bgColor="#F3F4F6" mb="5" pos="relative">
        <Flex
          justifyContent="space-between"
          py="3"
          px="4"
          borderBottom="1px solid rgba(25, 126, 239, 0.12)"
          onClick={toggleShow}
          cursor="pointer"
        >
          <Flex>
            <Flex
              bg="#E3F0FD"
              borderRadius="50%"
              h="32px"
              w="32px"
              alignItems="center"
              justifyContent="center"
            >
              <RencarIcon />
            </Flex>

            <Box ml="2">
              <Text>
                {" "}
                {business?.name.substring(0, 16) || "Unknown"}
                {business?.name.length > 16 && "..."}
              </Text>
              <Text fontSize="xs" color="brand.gray.350">
                Getting Started
              </Text>
            </Box>
          </Flex>

          <Text my="auto">{show ? <ArrowUpIcon /> : <ArrowDownIcon />}</Text>
        </Flex>
        {show && (
          <Box
            bg="#F3F4F6"
            pos="absolute"
            top="14"
            w="100%"
            boxShadow="sm"
            rounded="sm"
          >
            {businesses.length > 0 && (
              <Box
                maxH="40vh"
                overflow="scroll"
                whiteSpace="nowrap"
                d={show ? "block" : "none"}
              >
                {businesses.map((business) => (
                  <Box
                    py="4"
                    px="5"
                    transition="all .4s"
                    borderBottom="1px solid rgba(25, 126, 239, 0.12)"
                    cursor="pointer"
                    key={business.id}
                    onClick={() => {
                      handleSelectBusiness(business.uid);
                    }}
                  >
                    <Text fontSize="sm" color="brand.gray.350">
                      {business?.name}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}

            <Box
              py="4"
              px="5"
              d={show ? "block" : "none"}
              overflow="scroll"
              css={{
                "&::-webkit-scrollbar": {
                  width: "0.1px",
                },
              }}
              whiteSpace="nowrap"
              transition="all .4s"
              borderBottom="1px solid rgba(25, 126, 239, 0.12)"
            >
              <Text
                fontSize="sm"
                color="brand.gray.350"
                cursor="pointer"
                onClick={addBusinessDisclosure.onOpen}
              >
                Add new workspace
              </Text>
            </Box>
          </Box>
        )}
        {addBusinessDisclosure.isOpen && (
          <AddNewBusiness disclosure={addBusinessDisclosure} />
        )}
      </Box>
    </>
  );
};

export default Rencar;
