import { Button } from "@chakra-ui/react";

const CustomButton = ({
  text,
  loading,
  zIndex = 0,
  disabled = false,
  h = "44px",
  w = "100%",
  bg = "brand.100",
  color = "white",
  fontSize = "md",
  fontWeight = 400,
  rounded = "6px",
  onClick,
  borderWidth = 0,
  borderColor,
  as,
  href,
  to,
  leftIcon,
  ...props
}) => {
  return (
    <Button
      bg={bg}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      borderWidth={borderWidth}
      borderColor={borderColor}
      _hover={{ opacity: 0.8, textDecor: "none" }}
      disabled={disabled}
      _disabled={{ background: "brand.gray.400", color: "brand.gray.350" }}
      _active={{ background: "brand.gray.150", color: "brand.gray.350" }}
      _focus={{ background: bg, color }}
      isLoading={loading}
      rounded={rounded}
      leftIcon={leftIcon}

      mb="0"
      w={w}
      h={h}
      as={as}
      to={to}
      target={href && "_blank"}
      href={href}
      onClick={onClick}
      zIndex={zIndex}
      {...props}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
