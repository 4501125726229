import { businessDataState } from "../atoms/business";
import { useRecoilState } from "recoil";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToast, ListItem } from "@chakra-ui/react";
import { getActiveBusinessUid, setActiveBusinessUid } from "../helpers";
import {
  getBusiness,
  createBusiness as addBusiness,
  getWallet as fetchWallet,
  getAllBusinesses,
} from "../services";
import { useAuth } from "../hooks";
import mixpanel from "mixpanel-browser";
import dayjs from "dayjs";

export const useBusiness = () => {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const {
    state: { userData },
    getProfile,
  } = useAuth();

  const [shouldSelectBusiness, setShouldSelectBusiness] = useState(false);
  const toast = useToast();
  const [businessData, setBusinessData] = useRecoilState(businessDataState);
  const getBusinesses = useCallback(async () => {
    setLoading(true);
    try {
      const businesses = userData?.businesses;
      // const otherBusinesses =  userData?.business_teams?.map(bus => ({
      //   ...bus.business,
      // }));
      // const businessesList = [...businesses, ...otherBusinesses];

      // const uniqueBusinessess = businessesList.reduce((arr, item) => {
      //   const removed = arr.filter(i => i.uid !== item.uid);
      //   return [...removed, item];
      // }, []);

      if (businesses) {
        setBusinessData({
          ...businessData,
          businesses,
        });
        if (businesses.length === 1) {
          setActiveBusinessUid(businesses[0].uid);
          const activeBusiness = await getBusiness(businesses[0].uid);
          const wallet = await fetchWallet(businesses[0].uid);
          setBusinessData({
            ...businessData,
            activeBusiness,
            wallet,
            businesses,
          });
        }
        if (businesses.length > 1) {
          if (getActiveBusinessUid()) {
            const activeBus = businesses.find(
              (business) => business.uid === getActiveBusinessUid()
            );

            if (activeBus) {
              setActiveBusinessUid(activeBus.uid);
              const activeBusiness = await getBusiness(activeBus.uid);
              const wallet = await fetchWallet(activeBus.uid);
              setBusinessData({
                ...businessData,
                activeBusiness,
                wallet,
                businesses,
              });
            }
          } else {
            setShouldSelectBusiness(true);
          }
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }

    setLoading(false);
  }, [
    userData?.businesses,
    userData?.business_teams,
    setBusinessData,
    businessData,
    toast,
  ]);

  const getActiveBusiness = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getBusiness(getActiveBusinessUid());
      setBusinessData({
        ...businessData,
        activeBusiness: response,
      });
      setShouldSelectBusiness(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  }, [businessData, setBusinessData, toast]);

  const createBusiness = async (values, state = false) => {
    setLoading(true);
    try {
      const response = await addBusiness({
        ...values,
        user_uid: userData?.uid,
      });
      setActiveBusinessUid(response?.uid);
      await getProfile();
      await getBusinesses();
      toast({
        title: "Success",
        description: "Congratulations! You're all set up 🎉",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      if (!state) {
        history("/");
      }
      // mixpanel.track('create_business', {
      //   business_name: values.name,
      //   role: values.role,
      //   // user_id: values.user_uid,
      //   team_size: values.member_count,
      //   hear_about_us: values.referral,
      //   date_created: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      // });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  };

  const getWallet = async () => {
    setLoading(true);
    try {
      const response = await fetchWallet(getActiveBusinessUid());
      setBusinessData({
        ...businessData,
        wallet: response,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
    setLoading(false);
  };

  const checkBusinessIsTeammate = userData?.business_teams?.some((item) => {
    if (item?.business?.uid === businessData?.activeBusiness?.uid) {
      return true;
    }
    return false;
  });

  return {
    state: {
      loading,
      ...businessData,
      team_business: userData?.business_teams,
      shouldSelectBusiness,
      setShouldSelectBusiness,
      businessTeammate: checkBusinessIsTeammate,
    },
    getBusinesses,
    createBusiness,
    getActiveBusiness,
    getWallet,
  };
};
