import React from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

export const Loader = ({ title = 'Please wait...', height = '90vh' }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      h={height}
      flexDir="column"
      textAlign="center"
    >
      <Spinner thickness="4px" color="brand.100" size="lg" />
      <Text pt="4" fontWeight="500">
        {title}
      </Text>
    </Flex>
  );
};
