import { request } from '../../utils';

export const getDesignations = async () => {
  try {
    const res = await request.get('/designations');
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getReferral = async () => {
  try {
    const res = await request.get('/referral_channels');
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
