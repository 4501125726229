import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { tourState } from "../atoms/tour";
import { useRecoilState } from "recoil";
import TopNav from "./UI/Navigation/TopNav";
import Sidebar from "./UI/Navigation/Sidebar";
import NoticeBar from "./UI/Navigation/NoticeBar";
import Tour from "reactour";

const Layout = () => {
  const [tour, setTour] = useRecoilState(tourState);

  return (
    <Box bg="brand.60">
      <Tour
        steps={tour?.steps}
        isOpen={tour?.isOpen}
        onRequestClose={() => {
          setTour({ ...tour, isOpen: false });
        }}
        
      />
      <NoticeBar />
      <Flex>
        <Box w="223px" bg="white" display={{ base: "none", md: "block" }}>
          <Sidebar />
        </Box>

        <Box w="100%">
          <TopNav />
          <Box minH={"100vh"}>
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Layout;
