import { request } from "../../utils";

export const sendQuickSms = async ({
  business_uid,
  to = [],
  message,
  sender_name,
  route,
}) => {
  try {
    const url = `/sms/business/sms/send`;
    const response = await request.post(url, {
      business_uid,
      to,
      message,
      sender_name,
      route,
    });

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const sendQuickScheduleSms = async ({
  business_uid,
  to = [],
  message,
  sender_name,
  route,
  schedule_date,
}) => {
  try {
    const url = `/sms/business/sms/schedule/send`;
    const response = await request.post(url, {
      business_uid,
      to,
      message,
      sender_name,
      route,
      schedule_date,
    });

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const sendBulkSms = async (payload) => {
  try {
    const url = `/sms/business/sms/send-bulk`;
    const response = await request.post(url, payload);

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const sendSmartSms = async ({
  business_uid,
  customer_group_uid,
  message,
  sender_name,
  route,
  name,
}) => {
  try {
    const url = `/sms/business/sms/custom/send`;
    const response = await request.post(url, {
      business_uid,
      customer_group_uid,
      message,
      sender_name,
      route,
      name,
    });

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const sendBulkScheduledSms = async ({
  business_uid,
  customer_group_uid,
  name,
  message,
  sender_name,
  route,
  schedule_date,
}) => {
  try {
    const url = `/sms/business/sms/schedule/bulk/send`;
    const response = await request.post(url, {
      business_uid,
      customer_group_uid,
      message,
      sender_name,
      name,
      route,
      schedule_date,
    });

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSmsReport = async ({
  page,
  timeline,
  start_date,
  end_date,
  business_uid,
  perPage,
  phone_number,
  isfilter = false,
}) => {
  try {
    const url = `/sms/report/${business_uid}`;
    const response = await request.get(url, {
      params: {
        page,
        phone_number,
        timeline,
        start_date,
        end_date,
        perPage,
        isfilter,
      },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSingleSmsReport = async (sms_uid) => {
  try {
    const url = `/sms/status/report/${sms_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const pauseScheduleSms = async (schedule_sms_uid) => {
  try {
    const url = `/sms/business/sms/schedule/toggle-status/${schedule_sms_uid}`;
    const response = await request.get(url);
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getScheuledSms = async (key) => {
  const { business_uid, page = 1 } = key.queryKey[1];
  try {
    const url = `/sms/business/sms/schedule/${business_uid}`;
    const response = await request.get(url, {
      params: { page },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSmsById = async (sms_uid) => {
  try {
    const url = `/sms/business/sms/show/${sms_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBulkSmsReport = async (business_uid, payload) => {
  try {
    const url = `/sms/business/sms/bulk/${business_uid}`;
    const response = await request.get(url, {
      params: { ...payload },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBulkSmsReportStats = async (key) => {
  const { id } = key.queryKey[1];
  try {
    const url = `/sms/business/sms/bulk/report/${id}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getSmsBulkStatistics = async (
  business_uid,
  { filterFrequency }
) => {
  try {
    const url = `/sms/business/sms/statistics/report/${business_uid}`;
    const response = await request.get(url, {
      params: { frequency: filterFrequency },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSmsReportStatistics = async ({
  start_date,
  end_date,
  timeline,
  phone_number,
  business_uid,
}) => {
  try {
    const url = `/sms/statistics/${business_uid}`;
    const response = await request.get(url, {
      params: { phone_number, start_date, end_date, timeline },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportSMS = async ({
  start_date,
  end_date,
  business_uid,
  timeline,
  phone_number,
}) => {
  try {
    const url = `/sms/report/export/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline, phone_number },
    });
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getBulkSmsById = async (bulk_sms_uid) => {
  try {
    const url = `/sms/business/sms/bulk/details/${bulk_sms_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportBulkSms = async (payload) => {
  try {
    const url = `/sms/business/sms/export/${payload.business_uid}`;
    const response = await request.post(url, payload);

    return response?.data;
  } catch (error) {
    throw error;
  }
};
