import { perPage } from "../constant/paginationPerPage";
import { request } from "../utils";

export const getWallet = async (business_uid) => {
  try {
    const url = `/wallet`;
    const res = await request.post(url, {
      business_uid,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWalletHistory = async ({
  business_uid,
  type = undefined,
  page,
  per_page,
  start_date,
  end_date,
  isfilter = false,
}) => {
  try {
    const url = `/wallet/wallet-history/${business_uid}`;
    const res = await request.get(url, {
      params: {
        type,
        page,
        per_page,
        start_date,
        end_date,
        isfilter,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportWalletTransaction = async ({
  start_date,
  end_date,
  business_uid,
}) => {
  try {
    const url = `/wallet/transaction/export/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date },
    });
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const initiateFundWallet = async ({
  business_id,
  amount,
  redirect_url,
}) => {
  try {
    const url = `/wallet/initiate-fund-wallet`;

    if (redirect_url) {
      const res = await request.post(url, {
        business_id,
        service_code: "TS6",
        amount,
        redirect_url,
      });
      return res?.data?.data;
    }

    const res = await request.post(url, {
      business_id,
      service_code: "TS6",
      amount,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const fundWallet = async ({ reference, provider_reference }) => {
  try {
    const url = `/wallet/fund-wallet`;
    const res = await request.post(url, { reference, provider_reference });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateWalletThreshold = async ({
  wallet_uid,
  wallet_threshold,
}) => {
  try {
    const url = `/wallet/wallet-settings/${wallet_uid}`;
    const res = await request.patch(url, {
      wallet_threshold,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWalletSettings = async (wallet_uid) => {
  try {
    const url = `/wallet/wallet-settings/${wallet_uid}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getPricing = async ({ country_code, currency, country_alpha }) => {
  try {
    const url = `/pricing/${country_code}/${currency}/${country_alpha}`;
    const res = await request.get(url);
    console.log(res);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
