import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Loader } from "../components/Loader";
import { ProtectedRoute } from "./protected";
import { PublicRoute } from "./public";
import { CustomRoute } from "./CustomRoute";
import { protectedRoute, completeRouteList } from "./protected/router";
import { publicRoute } from "./public/router";
import { useAuth, useBusiness } from "../hooks";
import SwitchBusiness from "../components/SwitchBusinessAccount";
import NotFound from "../components/UI/NotFound";

export const AppRouter = () => {
  const [fetching, setFetching] = useState(false);

  const { state } = useAuth();
  const {
    getBusinesses,
    state: { shouldSelectBusiness },
  } = useBusiness();

  const fetchProfile = async () => {
    setFetching(true);

    if (state.userData?.businesses?.length > 0) {
      await getBusinesses();
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchProfile();
  }, [state.userData?.businesses?.length]);

  if (fetching) {
    return <Loader title="We are loading up your details... " />;
  }

  if (shouldSelectBusiness) {
    return <SwitchBusiness />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {protectedRoute.map((route) => {
            const Comp = route.component;
            if (route.protected) {
              return (
                <Route
                  key={route.name}
                  exact={route.exact}
                  path={route.path}
                  element={<Comp />}
                />
              );
            }
          })}
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<CustomRoute />}>
          {completeRouteList.map((route) => {
            const Comp = route.component;
            if (!route.protected) {
              return (
                <Route
                  key={route.name}
                  exact={route.exact}
                  path={route.path}
                  element={<Comp />}
                />
              );
            }
          })}
        </Route>
        <Route element={<PublicRoute />}>
          {publicRoute.map((route) => {
            const Comp = route.component;
            return (
              <Route
                key={route.name}
                exact={route.exact}
                path={route.path}
                element={<Comp />}
              />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};
