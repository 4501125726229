import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ENV, setAuthToken } from "./helpers";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: ENV.DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryparams = new URL(window.location).searchParams;
const g54Token = queryparams.get("g54-token");
if (g54Token) {
  localStorage.setItem("g54-token", g54Token);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.unregister();
reportWebVitals();
