import { request } from "../../utils";

export const getBusinessTemplates = async ({
  business_uid,
  page,
  per_page,
}) => {
  try {
    const url = `/whatsapp/template/business/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWhatsappBusinessDefaultOTPTemplates = async (business_uid) => {
  try {
    const url = `/whatsapp/default-otp-template`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const createTemplate = async ({
  language_code,
  business_id,
  name,
  template_body,
  template_type_uid,
}) => {
  try {
    const url = `/whatsapp/template`;
    const res = await request.post(url, {
      language_code,
      business_id,
      name,
      template_body,
      template_type_uid,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getTemplate = async (template_uid) => {
  try {
    const url = `/whatsapp/template/${template_uid}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateTemplate = async (
  template_uid,
  { template_type_uid, language_uid, name, message_body }
) => {
  try {
    const url = `/whatsapp/template/${template_uid}`;
    const res = await request.patch(url, {
      template_type_uid,
      language_uid,
      name,
      message_body,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const deleteTemplate = async (template_uid) => {
  try {
    const url = `/whatsapp/template/${template_uid}`;
    const res = await request.delete(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
