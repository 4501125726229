import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getAuthToken } from "../helpers";
import { useAuth } from "../hooks";

export const CustomRoute = () => {
  const { state } = useAuth();
  if (state?.userData?.businesses?.length > 0) {
    return <Navigate to="/" />;
  }
  return getAuthToken() ? <Outlet /> : <Navigate to="/login" />;
};
