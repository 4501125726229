import { request } from "../../utils";

export const createWhatsappNumber = async ({
  business_uid,
  phone_number,
  display_name,
  plan_uid,
  facebook_bmid_number,
  setup_option,
}) => {
  try {
    const url = `/whatsapp/whatsapp-number`;
    const res = await request.post(url, {
      business_uid,
      phone_number,
      display_name,
      plan_uid,
      facebook_bmid_number,
      setup_option,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const processWhatsappCode = async ({
  business_uid,
  phone_number,
  setup_option,
  session_id,
}) => {
  try {
    const url = `/whatsapp/whatsapp-number/process-qr-code`;
    const res = await request.post(url, {
      business_uid,
      phone_number,
      setup_option,
      session_id,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateWhatsappNumber = async ({
  display_name,
  business_uid,
  description,
  website_url,
  address,
  business_email,
  business_category,
  phone_number,
  whatsapp_number_uid,
  facebook_business_manager,
  webhook_url,
}) => {
  try {
    const url = `/whatsapp/whatsapp-number/update/${whatsapp_number_uid}`;
    const res = await request.patch(url, {
      display_name,
      business_uid,
      description,
      website_url,
      address,
      business_email,
      business_category,
      phone_number,
      facebook_business_manager,
      webhook_url,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const updateWhatsappNumberOperation = async (
  whatsapp_number_uid,
  {
    verification_otp,
    is_facebook_provider_accepted,
    facebook_id_number_approved,
    is_whatsapp_account_valid,
    is_otp_valid,
    is_active,
  }
) => {
  try {
    const url = `/whatsapp/whatsapp-number/operation/update/${whatsapp_number_uid}`;
    const res = await request.patch(url, {
      facebook_id_number_approved,
      is_whatsapp_account_valid,
      is_facebook_provider_accepted,
      is_otp_valid,
      is_active,
      verification_otp,
    });
    return res?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWhatsappNumbers = async ({
  business_uid,
  page = 1,
  per_page,
}) => {
  try {
    const url = business_uid
      ? `/whatsapp/whatsapp-number/${business_uid}`
      : `/whatsapp/whatsapp-number`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWhatsappNumberByUid = async (
  business_uid,
  whatsapp_number_uid
) => {
  try {
    const url = `/whatsapp/whatsapp-number/business/${business_uid}?per_page=100000`;
    const allNumbers = await request.get(url);
    const res = allNumbers.data.data.data.find(
      (item) => item.uid === whatsapp_number_uid
    );
    return res;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
