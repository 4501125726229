import { request } from "../../utils";

export const getWhatsappReports = async ({
  page = 1,
  phone_number,
  start_date,
  end_date,
  timeline,
  business_uid,
  perPage,
  isfilter = false,
}) => {
  try {
    const url = `/whatsapp/report/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        perPage,
        phone_number,
        start_date,
        end_date,
        timeline,
        isfilter,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getWhatsappStatisticsReports = async ({
  phone_number,
  start_date,
  end_date,
  timeline,
  business_uid,
}) => {
  try {
    const url = `/whatsapp/statistics/${business_uid}`;
    const res = await request.get(url, {
      params: { phone_number, start_date, end_date, timeline },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSingleWhatsappReport = async (whatsapp_uid) => {
  try {
    const url = `/whatsapp/status/${whatsapp_uid}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportWhatsapp = async ({
  start_date,
  end_date,
  business_uid,
  timeline,
  phone_number,
}) => {
  try {
    const url = `/whatsapp/report/export/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline, phone_number },
    });
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
