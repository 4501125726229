import React from 'react';
import { Text, TextProps, useClipboard } from '@chakra-ui/react';

// interface Props extends TextProps {
//   children?: React.ReactNode;
//   fontSize?: string;
//   fontWeight?: string;
//   color?: string;
//   textAlign?: any;
//   lineHeight?: string;
//   letterSpacing?: string;
//   textTransform?: any;
//   altFW?: any
//   copyOnClick?: boolean; 
//   maxChars?: number
//   text?: string
//   ellipsis?: boolean
// }

function CustomText({
  // children,
  fontSize,
  fontWeight,
  color,
  textAlign,
  lineHeight,
  letterSpacing,
  textTransform,
  altFW,
  children,
  // ellipsis = false,
  ...props
}) {
 
  
  return (
    <Text

      fontSize={fontSize || 'md'}
      fontWeight={fontWeight || 'normal'}
      color={color || 'inherit'}
      textAlign={textAlign || 'left'}
      lineHeight={lineHeight || 'normal'}
      letterSpacing={letterSpacing || 'normal'}
      textTransform={textTransform || 'none'}
      {...props}
    >
      {children}
    </Text>
  );
}

export default CustomText;
