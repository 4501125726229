import { perPage } from "../../constant/paginationPerPage";
import { request } from "../../utils";

export const getVerificationReports = async ({
  page = 1,
  end_date,
  start_date,
  timeline,
  business_uid,
  per_page,
  isfilter = false
}) => {
  try {
    const url = `/verification/reports/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        per_page,
        end_date,
        start_date,
        timeline,
        isfilter
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getVerificationReportStatistics = async (
  business_uid,
  { phone_number, from, to }
) => {
  try {
    const url = `/verification/verification/report/statistics/${business_uid}`;
    const response = await request.get(url, {
      params: { phone_number, from, to },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getVerificationStatistics = async ({
  timeline,
  start_date,
  end_date,
  business_uid,
}) => {
  try {
    const url = `/verification/statistics/report/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getSingleVerification = async (uid) => {
  try {
    const url = `/verification/verifications/${uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
