import { request } from "../../utils";

export const sendVoiceMessage = async ({
  type,
  customer_mobile_number,
  message,
  sender_name,
  business_uid,
}) => {
  try {
    const url = `/voice/business/voice/send`;
    const res = await request.post(url, {
      type,
      customer_mobile_number,
      message,
      sender_name,
      business_uid,
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const sendMp3Message = async (payload) => {
  try {
    const url = `/voice/business/voice/send-campaign`;
    const res = await request.post(url, payload);
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getVoiceReports = async ({
  page = 1,
  per_page,
  timeline,
  start_date,
  end_date,
  business_uid,
  isfilter = false,
  phone_number,
}) => {
  try {
    const url = `/voice/report/${business_uid}`;
    const res = await request.get(url, {
      params: {
        page,
        timeline,
        start_date,
        end_date,
        per_page,
        isfilter,
        phone_number,
      },
    });
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getVoiceSingleReport = async (voice_uid) => {
  try {
    const url = `/voice/status/${voice_uid}`;
    const res = await request.get(url);
    return res?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getVoiceReportStatistics = async ({
  phone_number,
  start_date,
  end_date,
  timeline,
  business_uid,
}) => {
  try {
    const url = `/voice/statistics/${business_uid}`;
    const response = await request.get(url, {
      params: {
        phone_number,
        start_date,
        end_date,
        timeline,
      },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportVoice = async ({
  start_date,
  end_date,
  business_uid,
  timeline,
  phone_number,
}) => {
  try {
    const url = `/voice/report/export/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline, phone_number },
    });
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
