import React from "react";
import { Box, Flex, Image, Text, Link as ChakraLink } from "@chakra-ui/react";
import { ENV } from "../../../helpers";
import { dashboardRoute } from "../../../constant/dashboardRoutes";
import { Link, Link as RLink, useLocation } from "react-router-dom";
import { ArrowDownIcon, ArrowUpIcon } from "../../../assets/svg";
import useNavMenu from "./useNavMenu";
import Rencar from "./Rencar";
// import

const Sidebar = ({ isOpen = false }) => {
  const location = useLocation();

  return (
    <Box
      pos={"relative"}
      w={isOpen ? "100%" : "223px"}
      mt={isOpen && 10}
      bg="white"
      zIndex={1}
      transition="all .4s"
    >
      <Box
        pos={isOpen ? "none" : "fixed"}
        w="inherit"
        h="95vh"
        overflowY={"scroll"}
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        }}
        zIndex={3}
      >
        <Box
          mb="3"
          py="4"
          borderColor="brand.150"
          borderBottomWidth={"0.5px"}
          borderRightWidth={"0.5px"}
        >
          <Image src={ENV.SENDCHAMP_LOGO} w="146px" mx={"auto"} />
        </Box>

        <Box>
          <Rencar />
          {dashboardRoute.map((route) => {
            const Icon = route.icon;
            const Beta = route?.beta
            return (
              <>
                {route.path ? (
                  <Text
                  textDecoration={'none'}
                  _hover = {{
                    textDecoration : 'none'
                  }}
                  // bg={route?.isExternal ? 'rgba(25, 126, 239, 0.04)' : 'initial'}
                  //   py = {route?.isExternal ?  '1em' : 'initial'}
                    // paddingBottom = {route.path === '/campaigns' ? '5px' : 'initial'}
                    display="flex"
                    alignItems={"center"}
                    justifyItems = 'space-between'
                    fontSize={"sm"}
                    className={route.className}
                    color={
                      route.path === location.pathname
                        ? "brand.100"
                        : "brand.gray.350"
                    }
                    as={route?.isExternal ? ChakraLink : RLink }
                    to={route.path}
                    target = {route?.isExternal ? "_blank" : ''}
                    mb="6"
                    key={route.id}
                    px="24px"
                    href={route.path}
                    >
                    <Flex>
                    <Icon
                      color={
                        route.path === location.pathname ? "#197EEF" : "#6682A2"
                      }
                    />
                    <Text as="span" ml="2">
                      {route.title}
                    </Text>
                    </Flex>
                    <div>
                    <Beta
                    color={
                      route.path === location.pathname ? "#197EEF" : "#6682A2"
                    }
                    />
                    </div>
                  </Text>
                ) : (
                  <Box key={route.id} mb="7">
                    <Text
                      textTransform="uppercase"
                      color="brand.100"
                      fontSize={"xs"}
                      fontWeight="500"
                      mb="3"
                      px="24px"
                    >
                      {route.title}
                    </Text>
                    {route.children.length > 0 &&
                      route.children.map((sub) => (
                        <DropNav key={sub.id} route={sub} />
                      ))}
                  </Box>
                )}
              </>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;

const DropNav = ({ route }) => {
  const Icon = route.icon;
  const Beta = route?.beta
  const { show, setShow } = useNavMenu();
  const location = useLocation();

  return (
    <>
      <Flex
        justifyContent="space-between"
        mt="5"
        px="24px"
        cursor={"pointer"}
        className={route.className}
        onClick={() => {
          setShow(!show);
        }}
      >
        <Flex alignItems={"center"}>
          <Icon color={show ? "#197EEF" : "#6682A2"} />
          <Text
            fontSize={"sm"}
            color={show ? "brand.100" : "brand.gray.300"}
            ml="2"
          >
            {route.title}
          </Text>
          {Beta? <Beta/> : ''}
        </Flex>
        {show ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Flex>
      {show && (
        <Box bg="brand.70" mb="3" mt="3" fontSize={"xs"} py="3">
          {route.children.map((child) => {
            const CIcon = child?.icon;
            return (
              <Flex
                alignItems="center"
                justifyContent={"space-between"}
                pl={"52px"}
                pr={"12px"}
                mb="2"
              >
                <Text
                  color={
                    child.path === `${location.pathname}${location.search}`
                      ? "brand.100"
                      : "brand.gray.350"
                  }
                  textDecoration="none"
                  as={child?.isExternal ? ChakraLink : RLink}
                  to={child.path}
                  href={child.path}
                  target={child?.isExternal && "_blank"}
                  cursor="pointer"
                  className={child.className}
                >
                  {child.title}
                </Text>
                {child?.icon && <CIcon />}
              </Flex>
            );
          })}
        </Box>
      )}
    </>
  );
};
