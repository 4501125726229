import Cookie from "js-cookie";

export const clearStorage = () => {
  localStorage.removeItem("s-auth-user");
  Cookie.remove("s-auth-token");
  Cookie.remove("sandbox-auth-token");
  Cookie.remove("s-active-business");
  Cookie.set("mode", "LIVE");
  localStorage.clear();
  sessionStorage.clear();
};

// export const currentUser = Cookie.getJSON("s-auth-user");
// export const currentSandboxUser = Cookie.getJSON("sandbox-auth-user");

// MODE === 'TEST' || MODE === 'LIVE'
export const getMode = Cookie.get("mode") || "LIVE";
export const setMode = (mode) => Cookie.set("mode", mode);

// AUTH TOKEN
export const getAuthToken = () =>
  getMode === "LIVE"
    ? Cookie.get("s-auth-token")
    : Cookie.get("sandbox-auth-token");

export const setAuthToken = (token) =>
  getMode === "LIVE"
    ? Cookie.set("s-auth-token", token)
    : Cookie.set("sandbox-auth-token", token);

export const setAuthUser = (user) =>
  localStorage.setItem("s-auth-user", JSON.stringify(user));

export const getAuthUser = () => JSON.parse(localStorage.getItem("s-auth-user"));

// ACTIVE BUSINESS
export const setActiveBusinessUid = (business_uid) =>
  Cookie.set("s-active-business", business_uid);

export const developerMode = Cookie.get("s-developer-mode") || false;
export const setDeveloperMode = (mode) => Cookie.set("s-developer-mode", mode);

export const removeActiveBusinessUid = () => Cookie.remove("s-active-business");

export const getActiveBusinessUid = () => Cookie.get("s-active-business");
