import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Input, InputProps } from "@chakra-ui/react";
import { useState } from "react";
import { EyeIcon } from "../../../assets/svg";
import styled from "@emotion/styled";
const CustomInput = ({
  name,
  defaultValue,
  disabled,
  isReadOnly,
  fontSize = "lg",
  height = "44px",
  borderRadius = "6px",
  borderWidth = "1px",
  borderColor = "brand.gray.200",
  type,
  max,
  id,
  maxLength,
  w,
  placeholder,
  onChange,
  py = "14px",
  pl = "16px",
  my = "4px",
  onKeyUp,
  onBlur,
  password,
  date,
  paddingRight,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <InputBox
    position = 'relative'
    >
    <Input
      isDisabled={disabled}
      paddingRight ={password ? '4em' : paddingRight}
      // _disabled={{
      //   color: "#000000",
      //   background: "#f2f2f2"
      // }}
      _focus={{ outline: "none" }}
      maxLength={maxLength}
      defaultValue={defaultValue}
      fontSize={fontSize}
      height={height}
      borderWidth={borderWidth}
      borderRadius={borderRadius}
      borderColor={borderColor}
      my={my}
      // type={type}
      id={id}
      placeholder={placeholder}
      _placeholder={{ color: "brand.gray.350", letterSpacing: "-3%" }}
      py={py}
      pl={pl}
      display="inline-block"
      onChange={onChange}
      max={max}
      name={name}
      w={w}
      isReadOnly={isReadOnly}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      type={`${
        (password && !visible && 'password') || type || (date && 'date')
      }`}
      position = 'relative'
      {...rest}
      // {...InputProps}
    />
    {password && (
      <>
        {visible ? (
          <EyeIcon
            id='unreveal'
            className='eyeSvg'

            onClick={() => setVisible(!visible)}
          />
        ) : (
          <EyeIcon
            id=''
            className='eyeSvg-Reveal'
            onClick={() => setVisible(!visible)}
          />
        )}
      </>
    )}
    </InputBox>
  );
};

CustomInput.Phone = ({ onChange, value, ...props }) => {
  return (
    <PhoneInput
      enableSearch={true}
      disableSearchIcon={true}
      value={value}
      country="ng"
      onChange={onChange}
      {...props}
    />
  );
};

export default CustomInput;


const InputBox = styled(Box)`
input[type="password"]::-ms-reveal {
  display: none !important;
}

input[type="password"] {
  -moz-appearance: none !important;
  appearance: none !important;
}

input[type="password"]::-webkit-contacts-auto-fill-button {
  display: none !important;
}

.eyeSvg{
    margin-left:-1.7em ;
    /* margin-top: 0.7em; */
    transition: 250ms;
    display: grid;
    place-items: center;

}

.eyeSvg::after{
    content: '';
    width: 2px;
    height: 1rem;
    background-color: rgb(26 32 44 / 62%);
    color: blue;
    display: block;
    rotate: 45deg;
    position: absolute;
    transition: 360ms ease-in;
    place-self: center;
}


.eyeSvg-Reveal{
  
    margin-left:-1.7em ;
    /* margin-top: 0.7em; */
    transition: 250ms;
    display: grid;
    place-items: center;
}

.eyeSvg-Reveal::after{
    content: '';
    width: 2px;
    height: 0px !important;
    background-color: red;
    color: blue;
    display: block;
    rotate: 45deg;
    position: absolute;
    transition: 340ms;
    place-self: center;
}




#unreveal{
    opacity: .58;
}
`