import { request } from "../../utils";

export const sendQuickEmail = async ({
  business_uid,
  to,
  message_body,
  subject,
}) => {
  try {
    const url = `/email/email/send`;
    const response = await request.post(url, {
      business_uid,
      to,
      message_body,
      subject,
    });

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailReport = async ({
  page = 1,
  email,
  start_date,
  end_date,
  timeline,
  business_uid,
  per_page,
  isfilter = false,
}) => {
  try {
    const url = `/email/reports/${business_uid}`;
    const response = await request.get(url, {
      params: {
        page,
        per_page,
        email,
        start_date,
        end_date,
        timeline,
        isfilter,
      },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailById = async (email_uid) => {
  try {
    const url = `/email/single-email/${email_uid}`;
    const response = await request.get(url);
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getEmailReportStatistics = async ({
  start_date,
  end_date,
  timeline,
  business_uid,
  email,
}) => {
  try {
    const url = `/email/statistics/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline, email },
    });
    return response?.data?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const exportEmail = async ({
  start_date,
  end_date,
  business_uid,
  timeline,
}) => {
  try {
    const url = `/email/report/export/${business_uid}`;
    const response = await request.get(url, {
      params: { start_date, end_date, timeline },
    });
    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
